import { useState, useCallback, useEffect, useMemo } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { validateZip } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { DownloadProjectActions, RootState } from 'store';

let chunks: ArrayBuffer[] = [];

const socketUrl = `${window.location.origin.replace('http', 'ws')}/api/v1/extract/zip/by-polygon`;

export const useWebSocketDownload = () => {
    const dispatch = useDispatch();
    const { polygonData } = useSelector((state: RootState) => state.downloadProject);
    const [connect, setConnect] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [fileName, setFileName] = useState('estrazione.zip');
    const [byteSize, setByteSize] = useState(0);
    const { sendJsonMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
        socketUrl,
        { share: false, heartbeat: true },
        connect
    );

    const startDownload = useCallback(() => {
        const date = new Date();
        const areaName = polygonData.polygon.geometry ? 'Poligono' : polygonData.polygon.properties.name;
        const name = `${date.toLocaleDateString('it').replaceAll('/', '-')}_${date
            .toLocaleTimeString(undefined, { hour12: false })
            .replaceAll(':', '-')}_${areaName}`;
        setFileName(name);
        setConnect(true);
    }, [setConnect, polygonData]);

    const sendRequest = useCallback(() => {
        dispatch(DownloadProjectActions.setState({ key: 'error', value: undefined }));
        setDownloading(true);
        sendJsonMessage(polygonData);
    }, [dispatch, sendJsonMessage, polygonData]);

    const checkAndDownload = useCallback(async () => {
        setDownloading(false);
        if (chunks.length === 0) return dispatch(DownloadProjectActions.setState({ key: 'showErrorModal', value: true }));
        const blob = new Blob(chunks, { type: 'application/zip' });
        chunks = [];
        setByteSize(0);
        const { error, empty } = await validateZip(blob);
        if (empty) dispatch(DownloadProjectActions.setState({ key: 'error', value: "L'estrazione non contiene dati" }));
        if (error) {
            dispatch(DownloadProjectActions.setState({ key: 'showErrorModal', value: true }));
            return;
        }
        const link = document.createElement('a');
        link.download = fileName;
        link.href = URL.createObjectURL(blob);
        link.click();
    }, [dispatch, fileName]);

    useEffect(() => {
        if (!downloading && readyState === ReadyState.OPEN && chunks.length === 0) {
            const socket = getWebSocket();
            (socket as any).binaryType = 'arraybuffer';
            sendRequest();
        }
        if (downloading && readyState === ReadyState.CLOSED) {
            setConnect(false);
            checkAndDownload();
        }
    }, [getWebSocket, sendRequest, checkAndDownload, readyState, downloading]);

    useEffect(() => {
        if (typeof lastMessage?.data === 'object' && lastMessage?.data instanceof ArrayBuffer) {
            chunks.push(lastMessage.data);
            setByteSize(prev => prev + lastMessage.data.byteLength);
        }
    }, [lastMessage]);

    const size = useMemo(() => {
        return byteSize > 0 ? (byteSize > 1024 ? `${Math.floor(byteSize / 1024)} kB` : `${byteSize} Bytes`) : undefined;
    }, [byteSize]);

    return { downloading, startDownload, size };
};

