import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Point, Polygon, map, zoomPadding, mapLayer, selectionLayer, createMapSource } from 'helpers';
import TileWMS from 'ol/source/TileWMS';
import TileLayer from 'ol/layer/Tile';
import OlMap from 'ol/Map';
import GeoJSON from 'ol/format/GeoJSON';
import LayerGroup from 'ol/layer/Group';
import Layer from 'ol/layer/Layer';
import BaseLayer from 'ol/layer/Base';
import { Geometry } from 'ol/geom';

export interface LayerGroupInfo {
    id: string;
    group: LayerGroup;
    visible: boolean;
    layers: { name: string; title: string; visible: boolean; layer: BaseLayer }[];
}

type MapStyle = { value: string; label: string };
const defaultMapStyles = [
    {
        value: 'maps:blank',
        label: 'Nessuno Stile'
    },
    {
        value: 'maps:osm',
        label: 'Open Street Map'
    }
];

interface State {
    loading: boolean;
    map: any;
    mapStyle: string;
    mapStyles: MapStyle[];
    layerGroupInfo: any[];
    currentZoom: number;
    lastZoom: number;
}

export interface MapState extends State {
    map: OlMap;
    layerGroupInfo: LayerGroupInfo[];
}

const name = 'mapState';
const initialState: State = createInitialState();
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const mapActions = { ...slice.actions };
export const mapReducer = slice.reducer;

function createInitialState() {
    return {
        loading: false,
        map: map,
        mapStyle: 'maps:blank',
        mapStyles: defaultMapStyles,
        layerGroupInfo: [],
        currentZoom: 0,
        lastZoom: 0
    } as State;
}

function createReducers() {
    return {
        setLoading,
        addLayer,
        toggleGroupVisibility,
        toggleLayerVisibility,
        initMapLayers,
        replaceMap,
        setMapStyles,
        updateCurrentZoom,
        zoom,
        zoomOnPolygon,
        zoomOnPoint,
        zoomOnGeometry,
        resetRotation
    };

    function setLoading(state: MapState, action: PayloadAction<boolean>) {
        state.loading = action.payload;
    }

    function addLayer(state: MapState, action: PayloadAction<LayerGroup | Layer>) {
        addLayerToMap(state.map, action.payload);
        if (action.payload instanceof LayerGroup) {
            const group = action.payload;
            const id = group.getProperties().id;
            const groupInfo: LayerGroupInfo = { id, group, visible: group.getVisible(), layers: [] };
            group.getLayers().forEach(layer => {
                const layerProps = layer.getProperties();
                if (!layerProps.toggleable) return;
                groupInfo.layers.push({
                    name: layerProps.name,
                    layer,
                    title: layerProps.title,
                    visible: layer.getVisible()
                });
            });
            const idx = state.layerGroupInfo.findIndex(value => value.id === id);
            if (idx === -1) state.layerGroupInfo.push(groupInfo);
            else state.layerGroupInfo[idx] = groupInfo;
        }
    }

    function toggleGroupVisibility(state: MapState, action: PayloadAction<string>) {
        const groupInfo = state.layerGroupInfo.find(value => value.id === action.payload);
        if (!groupInfo) return;
        groupInfo.group.setVisible(!groupInfo.visible);
        groupInfo.visible = !groupInfo.visible;
    }

    function toggleLayerVisibility(state: MapState, action: PayloadAction<{ groupId: string; layerId: string }>) {
        const groupInfo = state.layerGroupInfo.find(value => value.id === action.payload.groupId);
        if (!groupInfo) return;
        const layerInfo = groupInfo.layers.find(v => v.name === action.payload.layerId);
        if (!layerInfo) return;
        layerInfo.layer.setVisible(!layerInfo.visible);
        layerInfo.visible = !layerInfo.visible;
    }

    function initMapLayers(state: MapState) {
        const currentMapLayer = getLayer(state.map, 'map-layer');
        const currentSelectionLayer = getLayer(state.map, 'selection');
        if (!currentMapLayer) state.map.addLayer(mapLayer);
        if (!currentSelectionLayer) state.map.addLayer(selectionLayer);
    }

    function replaceMap(state: MapState, action: PayloadAction<string>) {
        const mapLayer = getLayer(state.map, 'map-layer') as TileLayer<TileWMS>;
        const layerName = action.payload;
        const source = layerName === 'maps:blank' ? null : createMapSource(layerName);
        mapLayer.setSource(source);
        state.mapStyle = layerName;
    }

    function setMapStyles(state: MapState, action: PayloadAction<MapStyle[]>) {
        state.mapStyles = action.payload;
    }

    function updateCurrentZoom(state: MapState, action: PayloadAction<void>) {
        const view = state.map.getView();
        const zoom = view.getZoom()!;
        state.currentZoom = zoom;
    }

    function zoom(state: MapState, action: PayloadAction<number>) {
        const view = state.map.getView();
        const zoom = view.getZoom()!;
        view.setZoom(zoom + action.payload);
    }

    function zoomOnPolygon(state: MapState, action: PayloadAction<Polygon>) {
        const feature = new GeoJSON().readFeature(action.payload);
        zoomOnGeometry(state, { type: 'map/zoomOnGeometry', payload: feature.getGeometry()! });
    }

    function zoomOnPoint(state: MapState, action: PayloadAction<Point>) {
        const feature = new GeoJSON().readFeature(action.payload);
        zoomOnGeometry(state, { type: 'map/zoomOnGeometry', payload: feature.getGeometry()! });
    }

    function zoomOnGeometry(state: MapState, action: PayloadAction<Geometry>) {
        const view = state.map.getView();
        const isPoint = action.payload.getType() === 'Point';
        let maxZoom = undefined;
        if (isPoint) maxZoom = ((state.lastZoom > 9 ? state.lastZoom : 9) + 19) / 2;
        view.fit(action.payload.getExtent()!, { maxZoom, padding: zoomPadding });
        if (!isPoint) state.lastZoom = view.getZoom()!;
    }

    function resetRotation(state: MapState) {
        const view = state.map.getView();
        view.setRotation(0);
    }
}

function getLayer(map: OlMap, layerId: string) {
    return map
        .getLayers()
        .getArray()
        .find((layer: any) => layer.getProperties().id === layerId);
}

function addLayerToMap(map: OlMap, layer: LayerGroup | Layer) {
    const currentLayer = getLayer(map, layer.getProperties().id);
    if (!currentLayer) {
        map.addLayer(layer);
        return;
    }
    const layers = [...map.getLayers().getArray()];
    const index = layers.indexOf(currentLayer);
    layers[index] = layer;
    map.setLayers(layers);
}

