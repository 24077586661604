import { FC } from 'react';

type CheckedProps = { fill?: string };

export const Checked: FC<CheckedProps> = ({ fill = 'white' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
            <g clip-path="url(#clip0_355_419)">
                <path
                    d="M2.9316 5.65053C2.85514 5.65072 2.77941 5.63572 2.70879 5.60641C2.63817 5.5771 2.57408 5.53405 2.52023 5.47977L0 2.96031L0.822741 2.13681L2.9316 4.24488L7.17726 0L8 0.82274L3.34297 5.47977C3.28912 5.53405 3.22503 5.5771 3.15441 5.60641C3.08379 5.63572 3.00806 5.65072 2.9316 5.65053Z"
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_355_419">
                    <rect width="8" height="5.65053" fill={fill} />
                </clipPath>
            </defs>
        </svg>
    );
};

