import { FC, useMemo } from 'react';
import { LeftToolBarTypes, modelTitles } from 'helpers';
import { OutlineContainer } from 'components/containers';
import classes from './LayerTool.module.scss';
import { Arrow } from 'components/icons/Arrow';
import { Layer } from 'components/icons';
import { LayerSelect, LayerSelectProps } from './layerSelect/LayerSelect';
import colors from 'styles/modules/colors.module.scss';
import { useSelector } from 'react-redux';
import { LayerGroupInfo, MapState, RootState } from 'store';

type LayerToolProps = FC<{
    activeTab: LeftToolBarTypes;
    setActiveTab: (tab: LeftToolBarTypes) => void;
}>;

const LAYER_GROUPS: Omit<LayerSelectProps, 'visible' | 'toggles'>[] = [
    {
        id: 'diretto',
        title: modelTitles['diretto'],
        color: colors.diretto,
        tooltip: 'Il modello diretto è lorem ipsum dolor sit amet'
    },
    {
        id: 'concessione',
        title: modelTitles['concessione'],
        color: colors.concessione,
        tooltip: 'Il modello concessione è lorem ipsum dolor sit amet'
    },
    {
        id: 'fwa',
        title: modelTitles['fwa'],
        color: colors.fwa,
        tooltip: 'FWA lorem ipsum sit dolor amet'
    }
];

const LayerTool: LayerToolProps = ({ activeTab, setActiveTab }) => {
    const { layerGroupInfo } = useSelector((x: RootState) => x.map as MapState);

    const layerGroups: LayerSelectProps[] = useMemo(() => {
        const groupInfo = layerGroupInfo as LayerGroupInfo[];

        return LAYER_GROUPS.filter(lg => groupInfo.map(gi => gi.id).includes(lg.id)).map(el => {
            const info = groupInfo.find(gi => gi.id === el.id)!;
            return {
                id: el.id,
                title: el.title,
                visible: info.visible,
                color: el.color,
                toggles: info.layers.map(l => {
                    return { id: l.name, label: l.title, visible: l.visible };
                }),
                tooltip: el.tooltip
            };
        });
    }, [layerGroupInfo]);

    return (
        <OutlineContainer className={classes.round}>
            <div className={classes.header} onClick={() => setActiveTab(LeftToolBarTypes.layer)}>
                <div className={classes.iconText}>
                    <Layer />
                    <b>Layer</b>
                </div>
                <Arrow direction={activeTab === LeftToolBarTypes.layer ? 'up' : 'down'} />
            </div>
            {activeTab === LeftToolBarTypes.layer && (
                <div>
                    {layerGroups.map(layer => (
                        <LayerSelect key={layer.id} {...layer} />
                    ))}
                </div>
            )}
        </OutlineContainer>
    );
};

export default LayerTool;
