import { FC } from 'react';

export const Undo: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="26" viewBox="0 0 20 26" fill="none">
            <path
                d="M10.2162 5.46708H2.33096L6.83242 0.965636L5.86769 0L0.200366 5.66731C0.136855 5.73056 0.0864641 5.80573 0.0520774 5.88851C0.0176907 5.97128 0 6.06004 0 6.14967C0 6.23931 0.0176907 6.32806 0.0520774 6.41083C0.0864641 6.49361 0.136855 6.56878 0.200366 6.63203L5.86769 12.2984L6.83242 11.3337L2.33096 6.83226H10.2162C12.449 6.83226 14.5903 7.71922 16.1691 9.29801C17.7479 10.8768 18.6348 13.0181 18.6348 15.2509C18.6348 17.4836 17.7479 19.6249 16.1691 21.2037C14.5903 22.7825 12.449 23.6695 10.2162 23.6695H2.50296V25.0346H10.2162C12.811 25.0346 15.2996 24.0038 17.1344 22.169C18.9692 20.3342 20 17.8457 20 15.2509C20 12.656 18.9692 10.1675 17.1344 8.33268C15.2996 6.49787 12.811 5.46708 10.2162 5.46708Z"
                fill="#0050DE"
            />
        </svg>
    );
};
