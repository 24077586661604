import { FC } from 'react';

export const Search: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            <path
                d="M18.8859 17.8316C20.7174 15.7268 21.6602 12.9932 21.5155 10.2069C21.3709 7.42064 20.1501 4.79934 18.1106 2.89555C16.071 0.991758 13.3719 -0.0458044 10.5823 0.00155264C7.79266 0.0489097 5.13036 1.17749 3.15658 3.14941C1.18279 5.12133 0.0517148 7.78255 0.00172975 10.5721C-0.0482553 13.3617 0.986762 16.0618 2.88863 18.1031C4.7905 20.1445 7.41065 21.3677 10.1968 21.515C12.9829 21.6623 15.7174 20.7221 17.8239 18.8926L24.0239 25.0866L25.0849 24.0256L18.8859 17.8316ZM20.0199 10.8776C20.0173 13.3033 19.0525 15.6289 17.3374 17.3443C15.6222 19.0597 13.2966 20.0246 10.8709 20.0276C9.06161 20.0268 7.29317 19.4895 5.78918 18.4838C4.28518 17.4781 3.11317 16.049 2.42129 14.3772C1.7294 12.7054 1.54873 10.866 1.90212 9.09159C2.25551 7.31715 3.12707 5.68733 4.40664 4.40818C5.68621 3.12903 7.31631 2.25798 9.09087 1.90518C10.8654 1.55237 12.7048 1.73364 14.3763 2.42607C16.0478 3.11851 17.4766 4.291 18.4818 5.79533C19.4871 7.29966 20.0237 9.06826 20.0239 10.8776H20.0199Z"
                fill="#0050DE"
            />
        </svg>
    );
};
