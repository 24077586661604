import { Circle, Info } from 'components/icons';
import { legendIcons, legendTitles, modelTitles } from 'helpers';
import { FC } from 'react';
import colors from 'styles/modules/colors.module.scss';
import classes from './LayerInfo.module.scss';

const models = [
    { id: 'diretto', title: modelTitles['diretto'], icon: <Circle fill={colors.diretto} /> },
    { id: 'concessione', title: modelTitles['concessione'], icon: <Circle fill={colors.concessione} /> },
    { id: 'fwa', title: modelTitles['fwa'], icon: <Circle fill={colors.fwa} /> },
    { id: 'antennaFWA', title: legendTitles['antennaFWA'], icon: <legendIcons.antennaFWA /> },
    { id: 'pcn', title: legendTitles['pcn'], icon: <legendIcons.pcn /> },
    { id: 'cno', title: legendTitles['cno'], icon: <legendIcons.cno /> },
    { id: 'nodo', title: legendTitles['nodo'], icon: <legendIcons.nodo /> },
    { id: 'pacpal', title: legendTitles['pacpal'], icon: <legendIcons.pacpal /> },
    { id: 'pozzetto', title: legendTitles['pozzetto'], icon: <legendIcons.pozzetto /> },
    { id: 'roe', title: legendTitles['roe'], icon: <legendIcons.roe /> },
    { id: 'pozzettoROE', title: legendTitles['pozzettoROE'], icon: <legendIcons.pozzettoROE /> },
    { id: 'unita', title: legendTitles['unita'], icon: <legendIcons.unita /> }
];

export const LayerInfo: FC = () => {
    return (
        <div className={`${classes.container} `}>
            {models.map(model => (
                <div className={classes.model} key={model.id}>
                    <div>{model.icon}</div> {model.title}
                </div>
            ))}
            <div className={`${classes.icon}`}>
                <Info />
            </div>
        </div>
    );
};

