import {
    RootState,
    mapActions,
    searchActions,
    useGetProjectsQuery,
    useGetProvincesQuery,
    useGetMunicipalitiesQuery,
    useGetRegionsQuery,
    useGetSrbsQuery
} from 'store';
import { AreaOption, ProjectOption, SrbOption } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useSearchOptions = () => {
    const dispatch = useDispatch();
    const { regionId, provinceId, projectId, municipalityId, srbId } = useSelector((x: RootState) => x.search);
    const { data: regions, isFetching: regionsLoading } = useGetRegionsQuery();
    const { data: provinces, isFetching: provincesLoading } = useGetProvincesQuery(regionId ?? 0, { skip: !regionId });
    const { data: municipalities, isFetching: municipalitiesLoading } = useGetMunicipalitiesQuery(provinceId ?? 0, {
        skip: !provinceId
    });
    const { data: projects, isFetching: projectsLoading } = useGetProjectsQuery(municipalityId ?? 0, { skip: !municipalityId });

    const regionOptions: AreaOption[] = useMemo(
        () =>
            regions
                ? regions.map(region => {
                      return { id: region.id, label: region.nome, bbox: region.bbox };
                  })
                : [],
        [regions]
    );
    const regionOption: AreaOption | undefined = useMemo(
        () => regionOptions.find(option => option.id === regionId) ?? { id: -1, label: '', bbox: undefined as any },
        [regionOptions, regionId]
    );

    const provinceOptions: AreaOption[] = useMemo(
        () =>
            regionId && provinces
                ? provinces.map(province => {
                      return { id: province.id, label: province.nome, bbox: province.bbox };
                  })
                : [],
        [provinces, regionId]
    );
    const provinceOption: AreaOption | undefined = useMemo(
        () => provinceOptions.find(option => option.id === provinceId) ?? { id: -1, label: '', bbox: undefined as any },
        [provinceOptions, provinceId]
    );

    const municipalityOptions: AreaOption[] = useMemo(
        () =>
            provinceId && municipalities
                ? municipalities.map(municipality => ({ id: municipality.id, label: municipality.nome, bbox: municipality.bbox }))
                : [],
        [municipalities, provinceId]
    );
    const municipalityOption: AreaOption | undefined = useMemo(
        () =>
            municipalityOptions.find(option => option.id === municipalityId) ?? {
                id: -1,
                label: '',
                bbox: undefined as any
            },
        [municipalityOptions, municipalityId]
    );

    const projectOptions: ProjectOption[] = useMemo(
        () =>
            municipalityId && projects
                ? projects.map(project => {
                      const labelSuffix = project.fwa ? '(Conc. FWA)' : project.diretto ? '(Diretto)' : '(Conc. FTTH)';
                      return {
                          id: `${project.nome}${project.fwa}${project.diretto}`,
                          label: `${project.nome} ${labelSuffix}`,
                          fwa: project.fwa,
                          bbox: project.bbox
                      };
                  })
                : [],
        [projects, municipalityId]
    );
    const projectOption: ProjectOption | undefined = useMemo(
        () => projectOptions.find(option => option.id === projectId) ?? { id: '', label: '', bbox: undefined as any, fwa: false },
        [projectOptions, projectId]
    );

    const { data: srbs, isFetching: srbsLoading } = useGetSrbsQuery(projectId ?? '', { skip: !projectId || !projectOption?.fwa });
    const srbOptions: SrbOption[] = useMemo(() => {
        if (!projectId || !projectOption?.fwa) return [];
        return srbs
            ? srbs.map(srb => {
                  return { id: srb.id, label: srb.codice, coords: srb.coords };
              })
            : [];
    }, [projectId, projectOption, srbs]);
    const srbOption: SrbOption | undefined = useMemo(
        () => srbOptions.find(option => option.label === srbId) ?? { id: -1, label: '', coords: undefined as any },
        [srbOptions, srbId]
    );

    const onRegionChangeHandler = (value: AreaOption) => {
        dispatch(mapActions.zoomOnPolygon(value.bbox));
        dispatch(searchActions.setRegionId(value.id));
        dispatch(searchActions.setProvinceId(undefined));
        dispatch(searchActions.setMunicipalityId(undefined));
        dispatch(searchActions.setProjectId(undefined));
        dispatch(searchActions.setSrbId(undefined));
    };

    const onProvinceChangeHandler = (value: AreaOption) => {
        dispatch(mapActions.zoomOnPolygon(value.bbox));
        dispatch(searchActions.setProvinceId(value.id));
        dispatch(searchActions.setMunicipalityId(undefined));
        dispatch(searchActions.setProjectId(undefined));
        dispatch(searchActions.setSrbId(undefined));
    };

    const onMunicipalityChangeHandler = (value: AreaOption) => {
        dispatch(mapActions.zoomOnPolygon(value.bbox));
        dispatch(searchActions.setMunicipalityId(value.id));
        dispatch(searchActions.setProjectId(undefined));
        dispatch(searchActions.setSrbId(undefined));
    };

    const onProjectChangeHandler = (value: ProjectOption) => {
        dispatch(mapActions.zoomOnPolygon(value.bbox));
        dispatch(searchActions.setProjectId(value.id));
        dispatch(searchActions.setSrbId(undefined));
    };

    const onSrbChangeHandler = (value: SrbOption) => {
        dispatch(mapActions.zoomOnPoint(value.coords));
        dispatch(searchActions.setSrbId(value.label));
    };

    return {
        regionOptions,
        regionOption,
        regionsLoading,
        provinceOptions,
        provinceOption,
        provincesLoading,
        municipalityOptions,
        municipalityOption,
        municipalitiesLoading,
        projectOptions,
        projectOption,
        projectsLoading,
        srbOptions,
        srbOption,
        srbsLoading,
        onRegionChangeHandler,
        onProvinceChangeHandler,
        onMunicipalityChangeHandler,
        onProjectChangeHandler,
        onSrbChangeHandler
    };
};

