import { FC, useCallback } from 'react';
import classes from './RightToolbar.module.scss';
import { ZoomIn, ZoomOut } from 'components/icons';
import { useDispatch } from 'react-redux';
import { mapActions } from 'store';
import { OutlineContainer } from 'components/containers';
import { AttributesTable } from './attributesTable/AttributesTable';

const RightToolbar: FC = () => {
    const dispatch = useDispatch();

    const zoomHandler = useCallback(
        (offset: number) => {
            dispatch(mapActions.zoom(offset));
        },
        [dispatch]
    );

    return (
        <div className={classes.container}>
            <OutlineContainer className={classes.iconButton} onClick={() => zoomHandler(1)}>
                <ZoomIn />
            </OutlineContainer>
            <OutlineContainer className={classes.iconButton} onClick={() => zoomHandler(-1)}>
                <ZoomOut />
            </OutlineContainer>
            <AttributesTable />
        </div>
    );
};

export default RightToolbar;

