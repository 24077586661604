import { Checkbox, Switch } from 'components/forms';
import { Circle } from 'components/icons';
import { FC, useMemo, useCallback } from 'react';
import classes from './LayerSelect.module.scss';
import { useDispatch } from 'react-redux';
import { mapActions } from 'store';

export type LayerSelectProps = {
    id: string;
    title: string;
    visible: boolean;
    color: string;
    toggles: { id: string; label: string; visible: boolean }[];
    tooltip: string;
};

export const LayerSelect: FC<LayerSelectProps> = ({ id, title, visible, color, toggles, tooltip }) => {
    const dispatch = useDispatch();

    const toggleGroupVisibility = () => {
        dispatch(mapActions.toggleGroupVisibility(id));
    };

    const toggleLayerVisibility = useCallback(
        (layerId: string) => {
            dispatch(mapActions.toggleLayerVisibility({ groupId: id, layerId }));
        },
        [dispatch, id]
    );

    const togglesBody = useMemo(
        () => (
            <>
                {toggles.map(toggle => (
                    <div className={classes.toggle} key={toggle.id}>
                        <Checkbox
                            isChecked={toggle.visible}
                            setIsChecked={() => toggleLayerVisibility(toggle.id)}
                            disabled={!visible}
                        />
                        {toggle.label}
                    </div>
                ))}
            </>
        ),
        [toggleLayerVisibility, toggles, visible]
    );

    return (
        <>
            <hr />
            <div className={classes.header}>
                <Switch onClick={toggleGroupVisibility} checked={visible} />
                <Circle fill={color} />
                <p style={{ color }}>
                    <b>{title}</b>
                </p>
            </div>
            {togglesBody}
        </>
    );
};
