import { useDispatch, useSelector } from 'react-redux';
import { DrawActions, DrawState, MapState, RootState, mapActions } from 'store';
import ResetDrawButton from './resetDrawButton/ResetDrawButton';
import UndoButton from './undoButton/UndoButton';
import classes from './DrawingTool.module.scss';
import { Edit, NewEdit } from 'components/icons';
import { OutlineContainer } from 'components/containers';
import { zoomPadding } from 'helpers';
import { GeoJSON } from 'ol/format';
import { useEffect } from 'react';

const DrawingTool = () => {
    const { drawingMode, isDrawing, drawLayer, draw, snap, modify } = useSelector((x: RootState) => x.draw as DrawState);
    const { map } = useSelector((x: RootState) => x.map as MapState);
    const dispatch = useDispatch();

    useEffect(() => {
        modify.on('modifyend', ev => {
            setTimeout(() => {
                const geoJSON = new GeoJSON().writeFeature(drawLayer.getSource()!.getFeatures()[0]);
                dispatch(DrawActions.setPolygon(geoJSON));
            }, 1);
        });
    }, [dispatch, drawLayer, modify]);

    function setIsDrawing(value: boolean) {
        dispatch(DrawActions.setIsDrawing(value));
    }

    function startDrawingHandler() {
        dispatch(DrawActions.setDrawingMode(true));
        dispatch(mapActions.addLayer(drawLayer));
        map.addInteraction(draw);
        draw.once('drawend', ev => {
            setTimeout(() => {
                map.getView().fit(drawLayer.getSource()?.getExtent()!, { padding: zoomPadding });
                const geoJSON = new GeoJSON().writeFeature(drawLayer.getSource()!.getFeatures()[0]);
                dispatch(DrawActions.setPolygon(geoJSON));
            }, 1);
            map.addInteraction(modify);
            map.addInteraction(snap);
            setTimeout(() => map.removeInteraction(draw), 500);
            setIsDrawing(false);
        });
        setIsDrawing(true);
    }

    function stopDrawingHandler() {
        map.removeInteraction(modify);
        map.removeInteraction(snap);
        drawLayer.getSource()?.clear();
        map.removeLayer(drawLayer);
        map.removeInteraction(draw);
        setIsDrawing(false);
        dispatch(DrawActions.setDrawingMode(false));
    }

    function resetDrawingHandler() {
        stopDrawingHandler();
        startDrawingHandler();
    }

    return (
        <div className={`${classes.container} ${drawingMode ? classes.drawingMode : ''} ${!isDrawing ? classes.notDrawing : ''}`}>
            {!drawingMode ? (
                <div className={classes.iconButtonText} onClick={startDrawingHandler}>
                    <Edit />
                    <b>Seleziona area</b>
                </div>
            ) : (
                <>
                    {!isDrawing && (
                        <OutlineContainer className={`${classes.iconButtonText} ${classes.light}`} onClick={resetDrawingHandler}>
                            <NewEdit />
                            <b>Nuova Area</b>
                        </OutlineContainer>
                    )}
                    <OutlineContainer className={classes.buttonGroup}>
                        <UndoButton
                            onClick={() => {
                                draw.removeLastPoint();
                            }}
                            disabled={!isDrawing}
                        />
                        <ResetDrawButton onClick={stopDrawingHandler} />
                    </OutlineContainer>
                </>
            )}
        </div>
    );
};

export default DrawingTool;

