import { FC } from 'react';

export const MenuOpen: FC = () => {
    return (
        <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 16.5V15H20.5V16.5H0.5ZM0.5 9.25V7.75H14.5V9.25H0.5ZM0.5 2V0.5H20.5V2H0.5Z" fill="#0050DE" />
        </svg>
    );
};

