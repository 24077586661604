import { FC } from 'react';

export const CNO: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="19" viewBox="0 0 14 19" fill="none">
            <path
                d="M13.15 0.330078H0.600002C0.400002 0.330078 0.240002 0.490078 0.240002 0.690078V18.0601C0.240002 18.2601 0.400002 18.4201 0.600002 18.4201H13.15C13.35 18.4201 13.51 18.2601 13.51 18.0601V0.690078C13.51 0.490078 13.35 0.330078 13.15 0.330078Z"
                fill="#EAF4F7"
            />
            <path
                d="M13.38 0H0.38C0.17 0 0 0.17 0 0.38V18.38C0 18.59 0.17 18.76 0.38 18.76H13.38C13.59 18.76 13.76 18.59 13.76 18.38V0.38C13.76 0.17 13.59 0 13.38 0ZM0.75 1.53L12.64 18H7.06L0.75 9.25V1.53ZM1.11 0.75H6.69L13.01 9.5V17.22L1.11 0.75ZM13 8.22L7.61 0.75H13V8.22ZM0.75 10.53L6.14 18H0.75V10.53Z"
                fill="#353839"
            />
        </svg>
    );
};

