import OlMap from 'ol/Map';
import View from 'ol/View';
import { Attribution, ScaleLine } from 'ol/control';
import { getTileInfo } from './functions';
import { PinchRotate, DragRotate } from 'ol/interaction';
import { AntennaFWA, PCN, CNO, Nodo, PACPAL, Pozzetto, ROE, PozzettoROE, Unita } from 'components/icons';
import { ModelKey, ResultEntity } from './interfaces';

// eslint-disable-next-line no-restricted-globals
var isPortrait = screen.availHeight > screen.availWidth;
const minZoom = isPortrait ? 5.3 : 6.3;

export const onlyTouchInput = window.matchMedia('(pointer: coarse)').matches;

export const statsMaxZoom = 8.45;

export const zoomPadding = [20, 20, 20, 20];

const attribution = new Attribution({
    collapsible: false
});

export const map = new OlMap({
    target: undefined,
    layers: [],
    view: new View({
        extent: [-2306585.58281504, 3371137.0747975595, 5030459.35145304, 7047334.27512644],
        center: [1361936.884319, 5209235.674962],
        projection: 'EPSG:3857',
        minZoom,
        maxZoom: 19,
        zoom: minZoom
    }),
    maxTilesLoading: 72,
    controls: [
        new ScaleLine({
            units: 'metric',
            bar: true,
            steps: 2,
            text: true,
            minWidth: 150,
            maxWidth: 150
        }),
        attribution
    ]
});
map.getInteractions().forEach(
    interaction => (interaction instanceof PinchRotate || interaction instanceof DragRotate) && map.removeInteraction(interaction)
);

export const epsg3857TileInfo = getTileInfo('EPSG:3857');

export const baseURL = `${window.location.protocol}//${window.location.host}`;

export const modelTitles = {
    diretto: 'Diretto Infratel',
    concessione: 'Concessione FTTH',
    fwa: 'Concessione FWA'
};

export const alternativeModelTitles = {
    diretto: 'Diretto',
    concessione: 'C. FTTH',
    fwa: 'C. FWA'
};

export const legendTitles = {
    antennaFWA: 'Antenna FWA',
    pcn: 'PCN',
    cno: 'CNO',
    nodo: 'Nodo operatore',
    pacpal: 'PAC PAL',
    pozzetto: 'Pozzetto',
    roe: 'ROE',
    pozzettoROE: 'Pozzetto - ROE',
    unita: 'Unità immobiliare'
};

export const legendIcons = {
    antennaFWA: AntennaFWA,
    pcn: PCN,
    cno: CNO,
    nodo: Nodo,
    pacpal: PACPAL,
    pozzetto: Pozzetto,
    roe: ROE,
    pozzettoROE: PozzettoROE,
    unita: Unita
};

export const fiberEntities = [
    { id: 'tratte', label: 'Infrastruttura' },
    { id: 'ui', label: !onlyTouchInput ? 'Unità Immobiliari' : 'UI' },
    { id: 'roe', label: 'ROE' }
];

export const fwaEntities = [
    { id: 'srb', label: 'SRB' },
    { id: 'ui', label: !onlyTouchInput ? 'Unità Immobiliari' : 'UI' },
    {
        id: 'area_copertura',
        label: !onlyTouchInput ? 'Area di copertura' : 'Copertura'
    }
];

export const modelEntities = {
    diretto: fiberEntities,
    concessione: fiberEntities,
    fwa: fwaEntities
};

export const ExtractionEntities: { model: ModelKey; entity: ResultEntity }[] = [
    { model: 'diretto', entity: 'tratte' },
    { model: 'diretto', entity: 'ui' },
    { model: 'diretto', entity: 'roe' },
    { model: 'concessione', entity: 'tratte' },
    { model: 'concessione', entity: 'ui' },
    { model: 'concessione', entity: 'roe' },
    { model: 'fwa', entity: 'srb' },
    { model: 'fwa', entity: 'ui' },
    { model: 'fwa', entity: 'area_copertura' }
];

