import { FC } from 'react';
import Navbar from './navbar/Navbar';
import Map from './map/Map';
import LeftToolBar from './map/leftToolBar/LeftToolBar';
import RightToolbar from './map/rightToolBar/RightToolBar';
import DrawingTool from './map/drawingTool/DrawingTool';
import BottomSnackbar from './map/bottomSnackbar/BottomSnackbar';
import { MapProvider } from 'context';
import { TableProvider } from 'context';

const Home: FC = () => {
    return (
        <MapProvider>
            <Navbar />
            <Map />
            <TableProvider>
                <LeftToolBar />
                <RightToolbar />
            </TableProvider>
            <DrawingTool />
            <BottomSnackbar />
        </MapProvider>
    );
};

export default Home;

