import { drawSource, drawLayer, draw, snap, modify, DrawAlertState } from 'helpers';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Snap, Modify, Draw } from 'ol/interaction';
import { Geometry } from 'ol/geom';

interface State {
    drawingMode: boolean;
    isDrawing: boolean;
    drawSource: any;
    drawLayer: any;
    draw: any;
    snap: any;
    modify: any;
    polygon: string | undefined;
}

export interface DrawState extends State {
    drawSource: VectorSource<Geometry>;
    drawLayer: VectorLayer<VectorSource<Geometry>>;
    draw: Draw;
    snap: Snap;
    modify: Modify;
}

const name = 'drawState';
const initialState: State = createInitialState();
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const DrawActions = { ...slice.actions };
export const drawReducer = slice.reducer;

function createInitialState() {
    return {
        drawingMode: false,
        isDrawing: false,
        drawSource,
        drawLayer,
        draw,
        snap,
        modify,
        polygon: undefined
    } as State;
}

function createReducers() {
    return { setDrawingMode, setIsDrawing, setPolygon };

    function setDrawingMode(state: DrawState, action: PayloadAction<boolean>) {
        state.drawingMode = action.payload;
        if (!action.payload) {
            window.dispatchEvent(new CustomEvent<DrawAlertState>('drawAlert', { detail: 'ok' }));
            setPolygon(state, { payload: undefined, type: 'draw/setPolygon' });
        }
    }

    function setIsDrawing(state: DrawState, action: PayloadAction<boolean>) {
        state.isDrawing = action.payload;
    }

    function setPolygon(state: DrawState, action: PayloadAction<typeof state.polygon>) {
        state.polygon = action.payload;
    }
}

