import { FC } from 'react';
import Navbar from './navbar/Navbar';
import LoginContainer from './login/LoginContainer';
import LoginForm from './login/LoginForm';

import { Routes, Route, Navigate } from 'react-router-dom';
import ResetPasswordForm from './login/ResetPasswordForm';
import ChangePasswordForm from './login/ChangePasswordForm';

const Routing = () => {
    return (
        <Routes>
            <Route path="" element={<LoginForm />} />
            <Route path="reset-password" element={<ResetPasswordForm />} />
            <Route path="change-password" element={<ChangePasswordForm />} />
            <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
    );
};

const Login: FC = () => {
    return (
        <>
            <Navbar />
            <LoginContainer>
                <Routing />
            </LoginContainer>
        </>
    );
};

export default Login;

