import { FC } from 'react';

export const ROE: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
            <path
                d="M10.2825 9.46251H0.8625C0.7425 9.46251 0.6325 9.40251 0.5725 9.29251C0.5125 9.18251 0.5125 9.06251 0.5725 8.95251L5.2825 0.79251C5.4025 0.58251 5.7525 0.58251 5.8725 0.79251L10.5825 8.95251C10.6425 9.06251 10.6425 9.18251 10.5825 9.29251C10.5225 9.40251 10.4125 9.46251 10.2925 9.46251H10.2825Z"
                fill="#EAF4F7"
            />
            <path
                d="M10.7725 9.7425H0.3825C0.2525 9.7425 0.1225 9.6725 0.0525 9.5525C-0.0175 9.4325 -0.0175 9.2925 0.0525 9.1725L5.2525 0.1725C5.3825 -0.0575 5.7725 -0.0575 5.9025 0.1725L11.1025 9.1725C11.1725 9.2925 11.1725 9.4325 11.1025 9.5525C11.0325 9.6725 10.9125 9.7425 10.7725 9.7425ZM1.0325 8.9925H10.1225L5.5725 1.1125L1.0225 8.9925H1.0325Z"
                fill="#353839"
            />
        </svg>
    );
};

