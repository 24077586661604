import { FC } from 'react';

export const Back: FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.2198 19.5L8.21984 12.5C8.15015 12.4303 8.09486 12.3476 8.05714 12.2566C8.01942 12.1656 8 12.068 8 11.9695C8 11.871 8.01942 11.7734 8.05714 11.6824C8.09486 11.5914 8.15015 11.5086 8.21984 11.439L15.2198 4.439L16.2808 5.5L9.81084 11.97L16.2808 18.439L15.2198 19.5Z"
                fill="#0050DE"
            />
        </svg>
    );
};

