import { FC } from 'react';

export const Edit: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path
                d="M2.17012 19.69C2.25012 19.69 2.34012 19.69 2.42012 19.67L8.69012 18.62C9.01012 18.57 9.30012 18.42 9.52012 18.19L19.8601 7.85C20.1501 7.56 20.3101 7.17 20.3101 6.76C20.3101 6.35 20.1501 5.96 19.8601 5.67L14.6401 0.45C14.3501 0.16 13.9601 0 13.5501 0C13.1401 0 12.7501 0.16 12.4601 0.45L2.13012 10.79C1.90012 11.02 1.75012 11.31 1.70012 11.62L0.650121 17.9C0.570121 18.39 0.730121 18.89 1.08012 19.24C1.37012 19.53 1.76012 19.69 2.16012 19.69H2.17012ZM3.22012 11.88L13.5601 1.53L18.7901 6.76L8.45012 17.1L2.18012 18.15L3.23012 11.87L3.22012 11.88Z"
                fill="white"
            />
            <path d="M24 22.59H0V24.12H24V22.59Z" fill="white" />
        </svg>
    );
};
