import { FC } from 'react';

export const ResizeBig: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27.1" height="27.102" viewBox="0 0 27.1 27.102" fill="#0050DE">
            <g id="resize_big" transform="translate(205.55 -1368.449) rotate(90)">
                <path
                    id="Path_221"
                    data-name="Path 221"
                    d="M1379.246,179.945v-1.5H1369.2a.749.749,0,0,0-.747.747v10.05h1.495V181l8.714,8.714,1.057-1.057L1371,179.945Z"
                />
                <path
                    id="Path_222"
                    data-name="Path 222"
                    d="M1394.056,194.753V203l-8.717-8.714-1.055,1.055,8.714,8.714h-8.245v1.5h10.05a.749.749,0,0,0,.747-.747v-10.05Z"
                />
            </g>
        </svg>
    );
};

