import formatter from 'ol/format/WMSCapabilities';
import { baseURL } from 'helpers';

const parseKeywordAttribute = (list: string[], keyword: string, toNumber: boolean) => {
    const found = list.find((value: string) => value.startsWith(`${keyword}:`));
    if (!found) return found;
    const value = found.split(':')[1];
    return toNumber ? +value : value;
};

export const getWorkspaceLayers = async (workspace: string) => {
    const data = await fetch(`${baseURL}/geoserver/${workspace}/wms?REQUEST=GetCapabilities`);
    const response = new formatter().read(await data.text());
    const layers: any[] = response.Capability?.Layer?.Layer;
    if (!layers) return [];
    return layers.map((layer: any) => {
        return {
            name: `${workspace}:${layer.Name}`,
            title: layer.Title as string,
            toggleable: layer.KeywordList.find((value: string) => value === 'toggleable') ? true : false,
            minZoom: parseKeywordAttribute(layer.KeywordList, 'minZoom', true) as number | undefined,
            maxZoom: parseKeywordAttribute(layer.KeywordList, 'maxZoom', true) as number | undefined,
            zIndex: parseKeywordAttribute(layer.KeywordList, 'zIndex', true) as number | undefined
        };
    });
};
