import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { OutlineContainer } from 'components/containers';
import formClasses from './FormContainer.module.scss';
import { OutlinedInput } from '@mui/material';
import { Link } from 'react-router-dom';
import { FC, useMemo, useState } from 'react';
import { baseURL } from 'helpers';
import { Snackbar } from 'components/containers';

import loginClasses from './Login.module.scss';
import PasswordInput from './passwordInput/PasswordInput';

interface ILoginForm {
    username: string;
    password: string;
}

const LoginForm: FC = () => {
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [wrongCredentials, setWrongCredentials] = useState(false);
    const { control, handleSubmit, formState } = useForm({
        defaultValues: {
            username: '',
            password: ''
        }
    });
    const isDisabled = useMemo(() => {
        if (isLoggingIn) return true;
        return !formState.isValid;
    }, [isLoggingIn, formState]);
    const errorBar = useMemo(() => {
        if (!wrongCredentials) return <></>;
        return <Snackbar text="Credenziali errate" isAlert={true} isOpen={wrongCredentials} setIsOpen={setWrongCredentials} />;
    }, [wrongCredentials]);

    const onSubmit: SubmitHandler<ILoginForm> = async data => {
        setIsLoggingIn(true);
        const res = await fetch(`${baseURL}/api/v1/login`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                username: data.username,
                password: data.password
            })
        });
        setIsLoggingIn(false);
        if (res.status === 200) {
            const resBody = await res.json();
            document.cookie = resBody.setCookie;
            document.location = '/geoserver/web';
        } else setWrongCredentials(true);
    };

    return (
        <div className={loginClasses.rootContainer}>
            <div></div>
            <OutlineContainer className={formClasses.rootContainer}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={formClasses.formContainer}>
                        <b>Benvenuto!</b>

                        <div className={formClasses.inputBox}>
                            <label htmlFor="username">Username</label>
                            <Controller
                                name="username"
                                control={control}
                                rules={{ required: 'Username Richiesto' }}
                                render={({ field }) => (
                                    <OutlinedInput
                                        classes={{ root: formClasses.outlinedInput }}
                                        autoComplete="username"
                                        error={wrongCredentials || !!formState.errors.username}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className={formClasses.inputBox}>
                            <label htmlFor="password">Password</label>
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: 'Password Richiesta' }}
                                render={({ field }) => (
                                    <PasswordInput error={wrongCredentials || !!formState.errors.password} {...field} />
                                )}
                            />
                        </div>
                        <div className={formClasses.forgotPasswordLabel}>
                            <span>Non ricordi la password?</span>
                            <Link to={'reset-password'}>Resetta la password</Link>
                        </div>
                    </div>
                    <button type="submit" className={`${formClasses.actionButton}`} disabled={isDisabled}>
                        <b>Login</b>
                    </button>
                </form>
            </OutlineContainer>
            <div className={loginClasses.errors}>{errorBar}</div>
        </div>
    );
};
export default LoginForm;

