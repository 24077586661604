import { useState, useMemo, useEffect } from 'react';
import { Snackbar } from 'components/containers';
import { useSelector } from 'react-redux';
import classes from './BottomSnackbar.module.scss';
import { DrawState, MapState, RootState } from 'store';
import { DrawAlertState, statsMaxZoom } from 'helpers';

const BottomSnackbar = () => {
    const { currentZoom } = useSelector((root: RootState) => root.map as MapState);
    const { isDrawing } = useSelector((root: RootState) => root.draw as DrawState);
    const [drawAlert, setDrawAlert] = useState<DrawAlertState>('ok');
    const [isRegionOpen, setIsRegionOpen] = useState<boolean>(true);
    const [isDrawingOpen, setIsDrawingOpen] = useState<boolean>(true);

    useEffect(() => {
        const handleDrawAlert = (event: CustomEvent<DrawAlertState>) => {
            setDrawAlert(event.detail);
        };
        window.addEventListener('drawAlert', handleDrawAlert as any);
        return () => window.removeEventListener('drawAlert', handleDrawAlert as any);
    }, []);

    const currentSnackbar = useMemo(() => {
        if (drawAlert === 'area-limit')
            return (
                <Snackbar
                    text="Riduci l'area del poligono per caricare gli attributi"
                    noClose
                    isAlert
                    isOpen={isDrawingOpen}
                    setIsOpen={setIsDrawingOpen}
                ></Snackbar>
            );
        if (drawAlert === 'self-intersection')
            return (
                <Snackbar
                    text="Forma geometrica non consentita"
                    noClose
                    isAlert
                    isOpen={isDrawingOpen}
                    setIsOpen={setIsDrawingOpen}
                ></Snackbar>
            );
        if (isDrawing)
            return (
                <Snackbar
                    text="Delimita un’area cliccando sulla mappa"
                    isOpen={isDrawingOpen}
                    setIsOpen={setIsDrawingOpen}
                ></Snackbar>
            );
        if (currentZoom < statsMaxZoom)
            return <Snackbar text="Seleziona una regione" isOpen={isRegionOpen} setIsOpen={setIsRegionOpen}></Snackbar>;
        return <></>;
    }, [drawAlert, currentZoom, isDrawing, isDrawingOpen, isRegionOpen]);

    return <div className={classes.root}>{currentSnackbar}</div>;
};

export default BottomSnackbar;

