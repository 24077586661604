import { FC } from 'react';

export const PACPAL: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 13 15" fill="none">
            <path
                d="M12.37 6.98998C12.37 6.98998 12.37 6.95998 12.37 6.93998C12.36 6.88998 12.34 6.83998 12.31 6.79998C12.31 6.79998 12.31 6.78998 12.31 6.77998L6.63 0.52998C6.5 0.37998 6.24 0.37998 6.11 0.52998L0.45 6.77998C0.45 6.77998 0.45 6.78998 0.45 6.79998C0.42 6.83998 0.4 6.87998 0.39 6.93998C0.39 6.95998 0.39 6.96998 0.39 6.98998C0.39 6.99998 0.39 7.00998 0.39 7.01998V13.97C0.39 14.17 0.55 14.32 0.74 14.32H12.05C12.25 14.32 12.4 14.16 12.4 13.97V7.01998C12.4 7.01998 12.4 6.99998 12.4 6.98998H12.37Z"
                fill="#EAF4F7"
            />
            <path
                d="M12.73 6.96C12.73 6.96 12.73 6.93 12.72 6.91C12.71 6.86 12.68 6.81 12.65 6.77C12.65 6.77 12.65 6.75 12.64 6.75L6.64 0.12C6.5 -0.04 6.23 -0.04 6.08 0.12L0.09 6.75C0.09 6.75 0.09 6.77 0.08 6.77C0.05 6.81 0.02 6.86 0.01 6.91C0.01 6.93 0.01 6.94 0 6.96C0 6.97 0 6.98 0 7V14.37C0 14.58 0.17 14.75 0.38 14.75H12.38C12.59 14.75 12.76 14.58 12.76 14.37V7C12.76 7 12.76 6.98 12.76 6.96H12.73ZM6.37 0.93L11.53 6.63H1.21L6.37 0.93ZM11.99 14H0.74V7.38H11.99V14Z"
                fill="#353839"
            />
        </svg>
    );
};

