import { Close } from 'components/icons';
import { FC } from 'react';
import classes from './Snackbar.module.scss';

type SnackbarProps = {
    text: string;
    isAlert?: boolean;
    noClose?: boolean;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
};

export const Snackbar: FC<SnackbarProps> = ({ text, noClose, isAlert, isOpen, setIsOpen }) => {
    if (!isOpen) return <></>;
    return (
        <div className={`${classes.root} ${noClose ? classes.noClose : ''} ${isAlert ? classes.alert : ''}`}>
            <div className={classes.text}>
                <b>{text}</b>
            </div>
            {!noClose && (
                <div className={classes.close} onClick={() => setIsOpen(false)}>
                    <Close />
                </div>
            )}
        </div>
    );
};

