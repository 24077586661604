import { FC } from 'react';

export const Unchecked: FC = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Checkbox">
                <rect id="Rectangle 96" x="0.5" y="0.5" width="19" height="19" rx="4.5" stroke="#ACB9BD" />
            </g>
        </svg>
    );
};
