import { FC } from 'react';

export const Cross: FC<{ fill?: string }> = ({ fill = '#5F686A' }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M27.6434 12.5508L26.7592 11.6666L19.655 18.775L12.5508 11.6708L11.6667 12.555L18.7708 19.6591L11.6667 26.7633L12.5508 27.6475L19.655 20.5433L26.7592 27.6475L27.6434 26.7633L20.5392 19.6591L27.6434 12.5508Z"
                fill={fill}
            />
        </svg>
    );
};

