import { FC } from 'react';

export const TableIcon: FC = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="4" fill="#0050DE" />
            <path
                d="M10.0539 29.5675C10.1063 29.6952 10.1954 29.8045 10.3101 29.8816C10.4247 29.9586 10.5596 29.9998 10.6977 30H29.3023C29.4874 30 29.6648 29.9265 29.7957 29.7957C29.9265 29.6648 30 29.4874 30 29.3023V10.6977C30 10.5126 29.9265 10.3352 29.7957 10.2043C29.6648 10.0735 29.4874 10 29.3023 10H10.6977C10.5126 10 10.3352 10.0735 10.2043 10.2043C10.0735 10.3352 10 10.5126 10 10.6977L10.0539 29.5675ZM11.4019 20.0791H16.1907V23.6447H11.4019V20.0791ZM17.5861 20.0791H22.3879V23.6447H17.5861V20.0791ZM28.5851 18.6837H23.7833V15.1628H28.5851V18.6837ZM22.3879 18.6837H17.5861V15.1628H22.3879V18.6837ZM23.7833 20.0791H28.5851V23.6447H23.7833V20.0791ZM16.1907 15.1628V18.6837H11.4019V15.1628H16.1907ZM11.4019 25.04H16.1907V28.5879H11.4019V25.04ZM17.5861 25.04H22.3879V28.5879H17.5861V25.04ZM23.7833 25.04H28.5851V28.5879H23.7833V25.04ZM28.6047 11.3963V13.7218H11.3953V11.3963H28.6047Z"
                fill="white"
            />
        </svg>
    );
};
