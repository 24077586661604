import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface State {
    regionId?: number;
    provinceId?: number;
    municipalityId?: number;
    projectId?: string;
    srbId?: string;
}

const DEFAULT_VALUE = {
    regionId: undefined,
    provinceId: undefined,
    municipalityId: undefined,
    projectId: undefined,
    srbId: undefined
} as State;

export interface SearchState extends State {}

const name = 'searchState';
const initialState: State = createInitialState();
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const searchActions = { ...slice.actions };
export const searchReducer = slice.reducer;

function createInitialState() {
    return DEFAULT_VALUE;
}

function createReducers() {
    return { setRegionId, setProvinceId, setMunicipalityId, setProjectId, setSrbId, resetSearch };

    function setRegionId(state: SearchState, action: PayloadAction<typeof state.regionId>) {
        state.regionId = action.payload;
    }
    function setProvinceId(state: SearchState, action: PayloadAction<typeof state.provinceId>) {
        state.provinceId = action.payload;
    }

    function setMunicipalityId(state: SearchState, action: PayloadAction<typeof state.municipalityId>) {
        state.municipalityId = action.payload;
    }

    function setProjectId(state: SearchState, action: PayloadAction<typeof state.projectId>) {
        state.projectId = action.payload;
    }
    function setSrbId(state: SearchState, action: PayloadAction<typeof state.srbId>) {
        state.srbId = action.payload;
    }
    function resetSearch(state: SearchState) {
        Object.keys(DEFAULT_VALUE).forEach(key => (state[key as keyof State] = undefined));
    }
}
