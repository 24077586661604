import { CircularProgress } from '@mui/material';
import { Checkbox, Switch } from 'components/forms';
import AutoCompleteInput from 'components/forms/AutoCompleteInput';
import { DownloadButton } from 'components/icons';
import { AreaOption } from 'helpers';
import { useSearchOptions, useWebSocketDownload } from 'hook';
import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DownloadProjectActions, RootState } from 'store';
import classes from './DownloadProjectBody.module.scss';
import { TableContext } from 'context';

const DOWNLOAD_ATTRIBUTES: Record<string, Record<string, string>> = {
    project: {
        diretto: 'Diretto Infratel',
        concessione: 'Concessione FTTH',
        fwa: 'Concessione FWA'
    },
    file: {
        csv: 'CSV',
        shape: 'Shape'
    }
};

const DownloadProjectBody: FC = () => {
    const { drawAlert } = useContext(TableContext);
    const { downloading, startDownload } = useWebSocketDownload();
    const dispatch = useDispatch();
    const { polygon } = useSelector((state: RootState) => state.draw);
    const { regionId } = useSelector((x: RootState) => x.search);
    const { regionOptions, regionOption, regionsLoading, onRegionChangeHandler } = useSearchOptions();
    const { isSelectedAreaChecked, projectCheckbox, enableDownload, fileCheckbox, isValid } = useSelector(
        (state: RootState) => state.downloadProject
    );

    useEffect(() => {
        if (!polygon)
            dispatch(
                DownloadProjectActions.setState({
                    key: 'isSelectedAreaChecked',
                    value: false
                })
            );
    }, [dispatch, polygon]);

    useEffect(() => {
        if (!isValid)
            dispatch(
                DownloadProjectActions.setState({
                    key: 'polygonData',
                    value: {}
                })
            );

        const extractionPolygon =
            isSelectedAreaChecked && drawAlert === 'ok' && polygon
                ? JSON.parse(polygon)
                : {
                      type: 'Feature',
                      geometry: null,
                      properties: { regionId: regionId, name: regionOption.label }
                  };
        const models: string[] = [];
        const types: string[] = [];
        Object.keys(projectCheckbox).forEach(key => {
            projectCheckbox[key] && models.push(key);
        });
        Object.keys(fileCheckbox).forEach(key => {
            fileCheckbox[key] && types.push(key);
        });

        dispatch(
            DownloadProjectActions.setState({
                key: 'polygonData',
                value: {
                    polygon: extractionPolygon,
                    options: { models: models, types: types }
                }
            })
        );
    }, [
        dispatch,
        drawAlert,
        isValid,
        isSelectedAreaChecked,
        polygon,
        projectCheckbox,
        fileCheckbox,
        regionId,
        regionOption.label
    ]);

    const project = useMemo(
        () => (
            <div className={classes.toggles}>
                <div className={classes.header}>Progetto</div>
                {Object.keys(DOWNLOAD_ATTRIBUTES.project).map(attribute => (
                    <div className={classes.toggle} key={attribute}>
                        <Checkbox
                            disabled={downloading}
                            isChecked={projectCheckbox[attribute]}
                            setIsChecked={() => {
                                dispatch(
                                    DownloadProjectActions.setState({
                                        key: 'projectCheckbox',
                                        value: {
                                            ...projectCheckbox,
                                            [attribute]: !projectCheckbox[attribute]
                                        }
                                    })
                                );
                            }}
                        />
                        {DOWNLOAD_ATTRIBUTES.project[attribute]}
                    </div>
                ))}
            </div>
        ),
        [dispatch, projectCheckbox, downloading]
    );

    const file = useMemo(
        () => (
            <div className={classes.toggles}>
                <div className={classes.header}>File</div>
                {Object.keys(DOWNLOAD_ATTRIBUTES.file).map(attribute => (
                    <div className={classes.toggle} key={attribute}>
                        <Checkbox
                            disabled={downloading}
                            isChecked={fileCheckbox[attribute]}
                            setIsChecked={() => {
                                dispatch(
                                    DownloadProjectActions.setState({
                                        key: 'fileCheckbox',
                                        value: {
                                            ...fileCheckbox,
                                            [attribute]: !fileCheckbox[attribute]
                                        }
                                    })
                                );
                            }}
                        />
                        {DOWNLOAD_ATTRIBUTES.file[attribute]}
                    </div>
                ))}
            </div>
        ),
        [dispatch, fileCheckbox, downloading]
    );

    const isFormValid = useCallback(() => {
        if (regionId || (isSelectedAreaChecked && drawAlert === 'ok' && polygon)) {
            if (projectCheckbox.diretto || projectCheckbox.concessione || projectCheckbox.fwa) {
                if (fileCheckbox.csv || fileCheckbox.shape) {
                    dispatch(
                        DownloadProjectActions.setState({
                            key: 'enableDownload',
                            value: true
                        })
                    );
                    dispatch(
                        DownloadProjectActions.setState({
                            key: 'isValid',
                            value: true
                        })
                    );
                    return;
                }
            }
        }
        dispatch(
            DownloadProjectActions.setState({
                key: 'enableDownload',
                value: false
            })
        );
        dispatch(
            DownloadProjectActions.setState({
                key: 'isValid',
                value: false
            })
        );
    }, [dispatch, drawAlert, regionId, isSelectedAreaChecked, polygon, projectCheckbox, fileCheckbox]);

    useEffect(() => {
        isFormValid();
    }, [regionId, isSelectedAreaChecked, polygon, projectCheckbox, fileCheckbox, isFormValid]);

    return (
        <>
            <hr />
            <div className={classes.body}>
                <AutoCompleteInput
                    disabled={drawAlert !== 'ok' ? false : isSelectedAreaChecked || downloading}
                    id="region"
                    label="Regione"
                    additionalInfo="(obbligatorio)"
                    placeholder="Nome Regione"
                    options={regionOptions}
                    onChange={(ev, value) => onRegionChangeHandler(value as AreaOption)}
                    value={regionOption}
                    loading={regionsLoading}
                />
            </div>
            <div className={classes.separator}>
                <span>Oppure</span>
            </div>
            <div className={classes.selectedArea}>
                <div className={classes.switch}>
                    <Switch
                        disabled={polygon === undefined || downloading}
                        checked={drawAlert !== 'ok' ? false : isSelectedAreaChecked}
                        onClick={() =>
                            dispatch(
                                DownloadProjectActions.setState({
                                    key: 'isSelectedAreaChecked',
                                    value: !isSelectedAreaChecked
                                })
                            )
                        }
                    />
                </div>
                <div className={classes.description}>
                    <b className={classes.header}>Area selezionata</b>
                    <div className={classes.text}>
                        {polygon !== undefined
                            ? drawAlert !== 'ok'
                                ? drawAlert === 'area-limit'
                                    ? 'Area troppo estesa'
                                    : 'Forma geometrica non consentita'
                                : 'Poligono'
                            : 'Nessuna area selezionata'}
                    </div>
                </div>
            </div>
            <div className={classes.checkboxWrapper}>
                {project}
                {file}
            </div>
            <div className={classes.downloadButtonContainer}>
                <div
                    onClick={startDownload}
                    className={`${classes.downloadButton} ${enableDownload && !downloading ? classes.enabled : ''}`}
                >
                    {downloading ? (
                        <>
                            <CircularProgress size={'1.5rem'} />
                            <b>Scaricamento...</b>
                        </>
                    ) : (
                        <>
                            <DownloadButton /> <b>Scarica</b>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default DownloadProjectBody;

