import { FC } from 'react';
import { Checkbox as MUICheckbox } from '@mui/material';
import classes from './Checkbox.module.scss';
import { Unchecked } from 'components/icons';

type CheckboxProps = {
    isChecked: boolean;
    setIsChecked: (value: boolean) => void;
    disabled?: boolean;
};

export const Checkbox: FC<CheckboxProps> = ({ disabled, isChecked, setIsChecked }) => {
    return (
        <MUICheckbox
            icon={<Unchecked />}
            checked={isChecked}
            onClick={() => setIsChecked(!isChecked)}
            disabled={disabled}
            classes={{
                root: classes.root,
                checked: classes.checked,
                disabled: classes.disabled
            }}
        />
    );
};
