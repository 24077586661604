import { FC } from 'react';

export const PozzettoROE: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
            <path
                d="M10.39 0.61001C10.39 0.42001 10.23 0.26001 10.04 0.26001H0.710001C0.520001 0.26001 0.360001 0.42001 0.360001 0.61001V7.15001C0.360001 7.34001 0.520001 7.50001 0.710001 7.50001H10.05C10.05 7.50001 10.14 7.50001 10.18 7.47001C10.22 7.45001 10.26 7.43001 10.29 7.39001C10.34 7.34001 10.37 7.27001 10.38 7.19001C10.38 7.19001 10.38 7.17001 10.38 7.16001C10.38 7.16001 10.38 7.15001 10.38 7.14001V0.61001H10.39Z"
                fill="#EAF4F7"
            />
            <path
                d="M10.75 0.38C10.75 0.17 10.58 0 10.37 0H0.38C0.17 0 0 0.17 0 0.38V7.38C0 7.59 0.17 7.76 0.38 7.76H10.38C10.38 7.76 10.48 7.76 10.52 7.73C10.57 7.71 10.61 7.68 10.64 7.65C10.7 7.59 10.73 7.52 10.74 7.44C10.74 7.44 10.74 7.42 10.74 7.41C10.74 7.41 10.74 7.4 10.74 7.39V0.38H10.75ZM10 6.21L6.1 0.75H10V6.21ZM3.62 2.19L1.56 0.75H4.64L3.61 2.19H3.62ZM5.38 1.02L8.8 5.81L4.23 2.61L5.37 1.01L5.38 1.02ZM3.19 2.8L0.75 6.21V1.1L3.18 2.8H3.19ZM3.8 3.23L9.19 7H1.1L3.79 3.23H3.8Z"
                fill="#353839"
            />
        </svg>
    );
};

