import { FC } from 'react';

export const Layer: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path
                d="M10.6318 15.0087C10.8563 15.1554 11.1437 15.1554 11.3682 15.0087L21.6952 8.13661C22.0095 7.92587 22.0903 7.49521 21.8927 7.18367C21.8389 7.10121 21.776 7.03707 21.6952 6.98209L11.3682 0.109954C11.1437 -0.0366514 10.8563 -0.0366514 10.6318 0.109954L0.304811 6.98209C-0.00948966 7.19284 -0.0903098 7.62349 0.10725 7.93503C0.16113 8.01749 0.223991 8.08163 0.304811 8.13661L10.6318 15.0087ZM11 1.50271L20.0967 7.55935L11 13.616L1.90325 7.55935L11 1.51187V1.50271Z"
                fill="#0050DE"
            />
            <path
                d="M18.633 11.8201L17.4027 12.6447L20.0967 14.4407L11 20.4973L1.90325 14.4315L4.59725 12.6356L3.36699 11.8109L0.304811 13.8634C-0.00948966 14.0741 -0.0903098 14.5048 0.10725 14.8163C0.16113 14.8988 0.223991 14.9629 0.304811 15.0179L10.6318 21.89C10.8563 22.0367 11.1437 22.0367 11.3682 21.89L21.6952 15.0179C22.0095 14.8072 22.0903 14.3765 21.8927 14.065C21.8389 13.9825 21.776 13.9184 21.6952 13.8634L18.633 11.8201Z"
                fill="#0050DE"
            />
        </svg>
    );
};
