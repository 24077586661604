import { DataGrid, GridCallbackDetails, GridColDef, GridPaginationModel, GridRowsProp } from '@mui/x-data-grid';
import { CircularProgress } from '@mui/material';
import classes from './Table.module.scss';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

type TableProps = {
    error: boolean;
    fetching: boolean;
    columns: GridColDef[];
    rows: GridRowsProp;
    rowCount: number;
    selectedModel: string;
    selectedEntity: string;
    setRequestedPage: (model: GridPaginationModel, details: GridCallbackDetails<any>) => void;
};

export const Table: FC<TableProps> = ({
    columns,
    rows,
    rowCount,
    error,
    fetching,
    selectedModel,
    selectedEntity,
    setRequestedPage
}) => {
    const key = useMemo(() => columns.map(v => v.field).join(), [columns]);

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 100
    });

    useEffect(() => {
        setPaginationModel({
            page: 0,
            pageSize: 100
        });
    }, [selectedEntity, selectedModel]);

    const onPaginationModelChange = useCallback(
        (model: GridPaginationModel, details: GridCallbackDetails<any>) => {
            setPaginationModel(model);
            setRequestedPage(model, details);
        },
        [setPaginationModel, setRequestedPage]
    );

    if (fetching)
        return (
            <div className={`${classes.spinner}`}>
                <CircularProgress size={'3rem'} />
            </div>
        );

    if (columns.length === 0) return <div className={classes.noData}>Nessun dato disponibile</div>;

    return (
        <DataGrid
            key={key}
            className={classes.root}
            sx={{
                '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                    outline: 'none'
                },
                '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
                    outline: 'none'
                }
            }}
            columns={columns.map(column => ({ ...column, hideSortIcons: true, sortable: false, editable: false }))}
            rowHeight={32}
            rows={rows}
            rowCount={rowCount}
            localeText={{
                noRowsLabel: '',
                MuiTablePagination: {
                    labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} su ${count}`
                }
            }}
            pageSizeOptions={[100]}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={onPaginationModelChange}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableRowSelectionOnClick
            disableDensitySelector
        />
    );
};

