import { FC } from 'react';

export const InfratelLogo: FC<{ fill?: string }> = ({ fill = '#FFF' }) => {
    return (
        <svg width="76" height="36" viewBox="0 0 76 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_910_5432)">
                <path d="M13.3333 6.03282V0H13.971V6.03282H13.3333Z" fill={fill} />
                <path
                    d="M19.4834 6.03282L16.3229 1.19711V6.03282H15.6862V0H16.2841L19.4446 4.81995V0H20.0814V6.03282H19.4834Z"
                    fill={fill}
                />
                <path
                    d="M22.4421 0.574929V2.79589H25.0512V3.37082H22.4421V6.03282H21.8053V0H25.4939V0.574929H22.4421Z"
                    fill={fill}
                />
                <path
                    d="M30.0677 6.03282L28.7165 3.35506H27.2876V6.03282H26.6509V0H28.9339C29.9667 0 30.6967 0.637935 30.6967 1.67753C30.6967 2.55961 30.1686 3.13454 29.3998 3.29206L30.8131 6.02494H30.0677V6.03282ZM28.8718 0.574929H27.2876V2.78801H28.8718C29.5629 2.78801 30.0599 2.42573 30.0599 1.68541C30.0521 0.945089 29.5629 0.574929 28.8718 0.574929Z"
                    fill={fill}
                />
                <path
                    d="M35.5967 6.03282L35.123 4.67031H32.5216L32.0479 6.03282H31.3723L33.5544 0H34.0902L36.2723 6.03282H35.5967ZM33.8339 0.921461L32.7157 4.10326H34.9366L33.8339 0.921461Z"
                    fill={fill}
                />
                <path d="M38.4776 0.574929V6.03282H37.8409V0.574929H36.1169V0H40.1938V0.574929H38.4776Z" fill={fill} />
                <path
                    d="M41.3042 6.03282V0H44.9928V0.574929H41.9332V2.70138H44.5424V3.27631H41.9332V5.45001H44.9928V6.02494H41.3042V6.03282Z"
                    fill={fill}
                />
                <path d="M46.3207 6.03282V0H46.9574V5.45789H50.0015V6.03282H46.3207Z" fill={fill} />
                <path d="M53.1853 6.03282V0H53.823V6.03282H53.1853Z" fill={fill} />
                <path d="M57.2855 0.574929V6.03282H56.6487V0.574929H54.9248V0H59.0016V0.574929H57.2855Z" fill={fill} />
                <path
                    d="M63.0784 6.03282L62.6047 4.67031H60.0033L59.5296 6.03282H58.854L61.0438 0H61.5797L63.754 6.03282H63.0784ZM61.3156 0.921461L60.1974 4.10326H62.4183L61.3156 0.921461Z"
                    fill={fill}
                />
                <path d="M64.7169 6.03282V0H65.3537V5.45789H68.3977V6.03282H64.7169Z" fill={fill} />
                <path d="M69.5004 6.03282V0H70.1381V6.03282H69.5004Z" fill={fill} />
                <path
                    d="M75.3245 6.03282L74.8508 4.67031H72.2494L71.7757 6.03282H71.1001L73.2899 0H73.8258L76.0078 6.03282H75.3245ZM73.5617 0.921461L72.4435 4.10326H74.6644L73.5617 0.921461Z"
                    fill={fill}
                />
                <path d="M13.9477 29.9672H13.3099V36H13.9477V29.9672Z" fill={fill} />
                <path d="M0.637721 21.8788H0V27.9116H0.637721V21.8788Z" fill={fill} />
                <path d="M13.9477 13.7825H13.3099V19.8154H13.9477V13.7825Z" fill={fill} />
                <path
                    d="M6.01823 27.9037L2.85771 23.068V27.9037H2.22095V21.8709H2.82665L5.9794 26.6909V21.8709H6.61617V27.9037H6.01823Z"
                    fill={fill}
                />
                <path
                    d="M10.1804 27.9037H9.66013L7.70325 21.8709H8.37107L9.92415 26.7539L11.4695 21.8709H12.1373L10.1804 27.9037Z"
                    fill={fill}
                />
                <path d="M13.9477 21.8709H13.3099V27.9037H13.9477V21.8709Z" fill={fill} />
                <path d="M17.3401 22.4459V27.9037H16.7033V22.4459H14.9872V21.8709H19.064V22.4459H17.3401Z" fill={fill} />
                <path
                    d="M23.1409 27.9037L22.6672 26.5412H20.0658L19.5921 27.9037H18.9165L21.1063 21.8709H21.6422L23.8242 27.9037H23.1409ZM21.3781 22.7924L20.2599 25.9821H22.4808L21.3781 22.7924Z"
                    fill={fill}
                />
                <path d="M24.7794 27.9037V21.8709H25.4162V27.3288H28.4602V27.9037H24.7794Z" fill={fill} />
                <path d="M30.0142 21.8709H29.3765V27.9037H30.0142V21.8709Z" fill={fill} />
                <path
                    d="M33.4378 22.7924L34.5405 25.9821H32.3196L33.4378 22.7924ZM33.1582 21.8709L30.9684 27.9037H31.644L32.1177 26.5412H34.7191L35.1928 27.9037H35.8684L33.6863 21.8709H33.1582Z"
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_910_5432">
                    <rect width="76" height="36" fill={fill} />
                </clipPath>
            </defs>
        </svg>
    );
};
