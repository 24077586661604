import AutoCompleteInput from 'components/forms/AutoCompleteInput';
import { AreaOption, ProjectOption, SrbOption } from 'helpers';
import classes from './ProjectFinderBody.module.scss';
import { useSearchOptions } from 'hook';

const ProjectFinderBody = () => {
    const {
        regionOptions,
        regionOption,
        regionsLoading,
        provinceOptions,
        provinceOption,
        provincesLoading,
        municipalityOptions,
        municipalityOption,
        municipalitiesLoading,
        projectOptions,
        projectOption,
        projectsLoading,
        srbOptions,
        srbOption,
        srbsLoading,
        onRegionChangeHandler,
        onProvinceChangeHandler,
        onMunicipalityChangeHandler,
        onProjectChangeHandler,
        onSrbChangeHandler
    } = useSearchOptions();

    return (
        <>
            <hr />
            <div className={classes.search}>
                <AutoCompleteInput
                    id="region"
                    label="Regione"
                    additionalInfo="(obbligatorio)"
                    placeholder="Nome Regione"
                    options={regionOptions}
                    onChange={(ev, value) => onRegionChangeHandler(value as AreaOption)}
                    value={regionOption}
                    loading={regionsLoading}
                />
                <AutoCompleteInput
                    id="province"
                    label="Provincia"
                    placeholder="Nome Provincia"
                    options={provinceOptions}
                    disabled={provinceOptions.length === 0}
                    onChange={(ev, value) => onProvinceChangeHandler(value as AreaOption)}
                    value={provinceOption}
                    loading={provincesLoading}
                />
                <AutoCompleteInput
                    id="municipality"
                    label="Comune"
                    placeholder="Nome Comune"
                    options={municipalityOptions}
                    disabled={municipalityOptions.length === 0}
                    onChange={(ev, value) => onMunicipalityChangeHandler(value as AreaOption)}
                    value={municipalityOption}
                    loading={municipalitiesLoading}
                />
                <AutoCompleteInput
                    id="project"
                    label="Progetto"
                    placeholder="nome_progetto"
                    options={projectOptions}
                    disabled={projectOptions.length === 0}
                    onChange={(ev, value) => onProjectChangeHandler(value as ProjectOption)}
                    value={projectOption}
                    loading={projectsLoading}
                />
                <AutoCompleteInput
                    id="srb_code"
                    label="Codice SRB"
                    placeholder="codice_srb"
                    options={srbOptions}
                    disabled={srbOptions.length === 0}
                    onChange={(ev, value) => onSrbChangeHandler(value as SrbOption)}
                    value={srbOption}
                    loading={srbsLoading}
                />
            </div>
        </>
    );
};

export default ProjectFinderBody;
