import { FC } from 'react';

export const DownloadButton: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="19" viewBox="0 0 24 19" fill="none">
            <path
                d="M12.701 11.29L12.701 2.95639e-05H11.201L11.201 11.29L4.42395 5.86903L3.48499 7.04103L11.48 13.441L11.6801 13.575L11.693 13.56C11.814 13.6041 11.9444 13.6164 12.0715 13.5955C12.1987 13.5747 12.3183 13.5215 12.4189 13.441L20.4141 7.04103L19.476 5.86903L12.701 11.29Z"
                fill="#ACB9BD"
            />
            <path d="M23.899 17.102H0V18.602H23.899V17.102Z" fill="#ACB9BD" />
        </svg>
    );
};
