import { FC } from 'react';

export const Download: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 25 20" fill="none">
            <path
                d="M24.4034 12.0399H24.3863C24.2741 11.0323 23.8883 10.0748 23.2705 9.27102C22.6527 8.46725 21.8267 7.84798 20.8819 7.48039C20.7933 5.47432 19.937 3.57907 18.4901 2.18672C17.0432 0.794374 15.1165 0.0114723 13.1085 6.74648e-06C11.4747 -0.00822909 9.88037 0.501249 8.55414 1.45534C7.22791 2.40942 6.23794 3.75909 5.72634 5.31068H5.71011C4.32205 5.31245 2.98402 5.82916 1.95503 6.76077C1.33931 7.31528 0.84683 7.99285 0.509432 8.74967C0.172034 9.50648 -0.0027721 10.3257 -0.00366211 11.1543V13.1121C-0.00294603 13.7612 0.255236 14.3836 0.71424 14.8426C1.17324 15.3016 1.79561 15.5598 2.44473 15.5605H8.84745V14.2123H2.44473C2.15309 14.2118 1.8735 14.0957 1.66728 13.8895C1.46106 13.6833 1.34499 13.4037 1.34452 13.1121V11.1543C1.34473 10.515 1.47944 9.88294 1.73982 9.29909C2.00021 8.71524 2.38048 8.19269 2.85595 7.76537C3.29529 7.36148 3.81577 7.05601 4.38262 6.86939C4.94948 6.68277 5.54966 6.61931 6.14301 6.68322C6.30607 6.70655 6.47193 6.66764 6.60761 6.57423C6.74329 6.48083 6.83881 6.33976 6.87522 6.17911C7.26585 4.6609 8.19756 3.33764 9.49518 2.45802C10.7928 1.5784 12.367 1.203 13.9219 1.40236C15.4768 1.60172 16.9054 2.36212 17.9391 3.54065C18.9728 4.71919 19.5405 6.2347 19.5355 7.80234C19.5373 7.82756 19.5373 7.85287 19.5355 7.87809C19.5154 8.03886 19.5538 8.20151 19.6437 8.33629C19.7336 8.47108 19.869 8.569 20.0252 8.61215C20.9074 8.86098 21.6842 9.39101 22.2376 10.1217C22.791 10.8523 23.0908 11.7437 23.0913 12.6603V13.1112C23.0909 13.403 22.9747 13.6827 22.7682 13.8889C22.5618 14.0952 22.282 14.2112 21.9902 14.2114H15.5875V15.5596H21.9902C22.6394 15.5589 23.2617 15.3007 23.7207 14.8417C24.1797 14.3827 24.4379 13.7603 24.4386 13.1112V12.2229L24.4034 12.0399Z"
                fill="#0050DE"
            />
            <path
                d="M12.8956 18.0675V10.2633H11.543V18.0675L9.77635 16.8951L9.02966 18.0161L11.8423 19.8891C11.9527 19.9641 12.0832 20.004 12.2166 20.0036C12.3499 20.0038 12.4802 19.9642 12.5908 19.89L15.4062 18.0161L14.6587 16.8951L12.8956 18.0675Z"
                fill="#0050DE"
            />
        </svg>
    );
};
