import { FC } from 'react';

export const Info: FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="info">
                <path
                    id="Path 277"
                    d="M12 0C9.62663 0 7.30655 0.703785 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913443 7.40779C0.00519179 9.60051 -0.232438 12.0133 0.230585 14.3411C0.693607 16.6688 1.8365 18.807 3.51473 20.4853C5.19296 22.1635 7.33114 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9965 8.81848 22.7311 5.76827 20.4814 3.5186C18.2317 1.26892 15.1815 0.00351588 12 0ZM12 22.677C9.8883 22.677 7.824 22.0508 6.06818 20.8776C4.31236 19.7044 2.94386 18.0369 2.13574 16.0859C1.32762 14.1349 1.1162 11.9882 1.52817 9.91702C1.94015 7.84588 2.95703 5.94342 4.45024 4.45022C5.94345 2.95701 7.84589 1.94012 9.91703 1.52815C11.9882 1.11617 14.135 1.32761 16.0859 2.13573C18.0369 2.94385 19.7044 4.31234 20.8776 6.06817C22.0508 7.82399 22.677 9.88829 22.677 12C22.6737 14.8307 21.5478 17.5445 19.5462 19.5462C17.5445 21.5478 14.8307 22.6737 12 22.677Z"
                    fill="#909CA0"
                />
                <path
                    id="Path 278"
                    d="M12.6623 10.9108C12.6621 10.7352 12.5922 10.5668 12.4681 10.4427C12.3439 10.3185 12.1755 10.2486 11.9999 10.2484H9.20605V11.5723H11.3376V16.3542H9.20605V17.6781H14.7938V16.3542H12.6623V10.9108Z"
                    fill="#909CA0"
                />
                <path
                    id="Path 279"
                    d="M11.9999 8.51803C12.2172 8.51803 12.4296 8.45359 12.6102 8.33285C12.7908 8.21212 12.9315 8.04051 13.0146 7.83976C13.0976 7.63901 13.1193 7.41814 13.0768 7.20509C13.0343 6.99203 12.9295 6.79638 12.7757 6.64288C12.622 6.48938 12.4262 6.38494 12.2131 6.34277C11.9999 6.3006 11.7791 6.3226 11.5785 6.40598C11.3779 6.48937 11.2065 6.63038 11.0861 6.81119C10.9656 6.992 10.9015 7.20448 10.9019 7.42173C10.9026 7.71258 11.0185 7.99129 11.2243 8.19678C11.4302 8.40228 11.7091 8.51779 11.9999 8.51803Z"
                    fill="#909CA0"
                />
            </g>
        </svg>
    );
};
