import { configureStore } from '@reduxjs/toolkit';
import { mapReducer } from './map.slice';
import { drawReducer } from './draw.slice';
import { searchReducer } from './search.slice';
import { popupReducer } from './popup.slice';
import { searchApi } from './rtk/search.service';
import { statsApi } from './rtk/stats.service';
import { downloadProjectReducer } from './downloadProject.slice';
import { resetPasswordReducer } from './resetPassword.slice';

export * from './map.slice';
export * from './draw.slice';
export * from './search.slice';
export * from './popup.slice';
export * from './rtk/search.service';
export * from './rtk/stats.service';
export * from './downloadProject.slice';
export * from './resetPassword.slice';

export const store = configureStore({
    reducer: {
        map: mapReducer,
        search: searchReducer,
        draw: drawReducer,
        popup: popupReducer,
        downloadProject: downloadProjectReducer,
        resetPassword: resetPasswordReducer,
        [searchApi.reducerPath]: searchApi.reducer,
        [statsApi.reducerPath]: statsApi.reducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({ serializableCheck: false }).concat(searchApi.middleware).concat(statsApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>;

