import {
    baseURL,
    checkAndDispatchDrawAlert,
    DrawAlertState,
    ExtractionEntities,
    ModelKey,
    Result,
    ResultEntity,
    ResultKey
} from 'helpers';
import { createContext, FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GeoJSON } from 'ol/format';
import { RootState } from 'store';
import { Feature } from 'ol';

const workerSrc = `
const abortController = new AbortController();

onmessage = e => {
    if (e.data.event === 'abort') {
        abortController.abort();
        return;
    }
    try {
        fetch(e.data.input, { ...e.data.init, signal: abortController.signal })
            .then(value => value.json())
            .then(value => {
                const data = [];
                const extraction = value.extraction;
                const idSet = new Set();
                for (const value of extraction) {
                    if (idSet.has(value.id)) continue;
                    idSet.add(value.id);
                    data.push(value);
                }
                postMessage({rowCount: value.rowCount, extraction: data});
            })
            .catch(e => {
                if (e instanceof DOMException && e.name === 'AbortError') return postMessage('ABORTED');
                throw e;
            });
    } catch (e) {
        console.error('ERROR', e);
        postMessage('ERROR');
    }
};
`;

interface WorkerMessage {
    event: 'start' | 'abort';
    input?: RequestInfo | URL;
    init?: RequestInit;
}

export type TableDataRequests = Record<ResultKey | string, Promise<void> | undefined>;
export type TableData = Record<ResultKey | string, undefined | Result> | undefined;

export const TableContext = createContext<{
    tableData: TableData;
    tableDataRequests: TableDataRequests;
    drawAlert: DrawAlertState;
    requestTableEntity: (
        polygon: string | undefined,
        model: ModelKey,
        entity: ResultEntity,
        signal?: AbortSignal,
        offset?: number
    ) => void;
}>({
    tableData: {},
    tableDataRequests: {},
    drawAlert: 'ok',
    requestTableEntity: () => {}
});

export const TableProvider: FC<PropsWithChildren> = ({ children }) => {
    const { polygon } = useSelector((root: RootState) => root.draw);
    const [abortController, setAbortController] = useState(new AbortController());
    const [tableDataRequests, setTableDataRequests] = useState<TableDataRequests>({});
    const [tableData, setTableData] = useState<TableData>({});
    const [drawAlert, setDrawAlert] = useState<DrawAlertState>('ok');

    const requestTableEntity = useCallback(
        (polygon: string | undefined, model: ModelKey, entity: ResultEntity, signal?: AbortSignal, offset = 0) => {
            if (!polygon) return setTableData(undefined);
            if (
                !signal &&
                tableData &&
                tableData[`${model}_${entity}`] &&
                tableData[`${model}_${entity}`]!.extraction.length > offset
            )
                return;

            const workerUrl = URL.createObjectURL(new Blob([workerSrc]));
            const worker = new Worker(workerUrl);

            signal?.addEventListener('abort', () =>
                worker.postMessage({
                    event: 'abort'
                })
            );

            const body = new Blob([JSON.stringify({ polygon: JSON.parse(polygon ?? '{}'), model, entity, offset })]);

            const msg: WorkerMessage = {
                event: 'start',
                input: `${baseURL}/api/v1/extract/table/by-polygon`,
                init: {
                    method: 'POST',
                    headers: { 'content-type': 'application/json' },
                    body,
                    keepalive: false
                }
            };

            const requestPromise = new Promise<Result | undefined>(resolve => {
                worker.postMessage(msg);
                worker.onmessage = ev => {
                    if (ev.data === 'ERROR') resolve(undefined);
                    if (ev.data === 'ABORTED') resolve(undefined);
                    resolve(ev.data);
                };
            });

            requestPromise.finally(() => {
                (requestPromise as any).done = true;
            });

            const dataPromise = requestPromise.then(value => {
                worker.terminate();
                setTableDataRequests(prev => ({ ...prev, [`${model}_${entity}`]: undefined }));
                if (value && offset === 0) {
                    setTableData(prev => ({
                        ...prev,
                        [`${model}_${entity}`]: { rowCount: value.rowCount, extraction: value.extraction }
                    }));
                } else if (value && offset > 0) {
                    setTableData(prev => {
                        if (!prev) return undefined;
                        const prevSection = prev[`${model}_${entity}`];
                        if (prevSection)
                            return {
                                ...prev,
                                [`${model}_${entity}`]: {
                                    ...prevSection,
                                    extraction: [...prevSection.extraction, ...value.extraction]
                                }
                            };
                        else return prev;
                    });
                }
            });

            setTableDataRequests(prev => ({ ...prev, [`${model}_${entity}`]: dataPromise }));
        },
        [tableData]
    );

    const queueEntityExtractions = useCallback(
        (polygon: string | undefined) => {
            abortController.abort();
            const replacement = new AbortController();
            setAbortController(replacement);
            if (!polygon) {
                setDrawAlert('ok');
                return setTableDataRequests({});
            }
            const transformedFeature = new GeoJSON().readFeature(polygon).getGeometry()?.transform('EPSG:3857', 'EPSG:4326');
            const geoJSON = new GeoJSON().writeFeature(new Feature(transformedFeature));
            const drawStatus = checkAndDispatchDrawAlert(geoJSON, false);
            setDrawAlert(drawStatus.detail);
            if (drawStatus.detail !== 'ok') return;
            ExtractionEntities.forEach(v => requestTableEntity(polygon, v.model, v.entity, replacement.signal));
        },
        [abortController, requestTableEntity]
    );

    useEffect(() => {
        setTableData({});
        queueEntityExtractions(polygon);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [polygon]);

    return (
        <TableContext.Provider value={{ tableData, tableDataRequests, drawAlert, requestTableEntity }}>
            {children}
        </TableContext.Provider>
    );
};

