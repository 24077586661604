import { FC } from 'react';

export const AntennaFWA: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
            <g clipPath="url(#clip0_1204_10703)">
                <path
                    d="M5.92308 2.95683C5.92308 1.88111 5.06154 1.00806 4 1.00806C2.93846 1.00806 2.07693 1.88111 2.07693 2.95683C2.07693 3.68437 2.48205 4.34956 3.11282 4.68215L0.487183 13.9999H1.33846L4 11.3028L6.66154 13.9999H7.51282L4.88718 4.68215C5.51795 4.34956 5.92308 3.67918 5.92308 2.95683ZM2.84616 2.95683C2.84616 2.31244 3.36411 1.78757 4 1.78757C4.6359 1.78757 5.15385 2.31244 5.15385 2.95683C5.15385 3.60123 4.6359 4.1261 4 4.1261C3.36411 4.1261 2.84616 3.60123 2.84616 2.95683ZM4 6.77643L3.4718 6.24117L3.85129 4.90041C3.95385 4.9108 4.04103 4.9108 4.14359 4.90041L4.52308 6.24636L3.99487 6.78163L4 6.77643ZM3.05129 7.73783L3.23077 7.10382L3.45641 7.33248L3.05129 7.74302V7.73783ZM4 7.87814L5.14872 9.04221L4 10.2063L2.85129 9.04221L4 7.87814ZM4.54359 7.32728L4.76923 7.09863L4.94872 7.73263L4.54359 7.32209V7.32728ZM3.45641 10.7519L1.70257 12.5292L2.48205 9.75936L3.45641 10.7467V10.7519ZM6.29744 12.5292L4.54359 10.7519L5.51795 9.76455L6.29744 12.5292Z"
                    fill="#353839"
                />
                <path
                    d="M1.26154 5.91388L1.80513 5.36303L1.71282 5.26949C1.10256 4.65108 0.764103 3.82999 0.764103 2.95694C0.764103 2.08389 1.10256 1.26281 1.71282 0.644395L1.80513 0.550854L1.26154 0L1.16923 0.0935412C0.415385 0.857461 0 1.87602 0 2.95694C0 4.03786 0.415385 5.05642 1.17436 5.82034L1.26667 5.91388H1.26154Z"
                    fill="#353839"
                />
                <path
                    d="M6.19482 5.36303L6.73841 5.91388L6.83072 5.82034C7.5897 5.05122 8.00508 4.03786 8.00508 2.95694C8.00508 1.87602 7.58457 0.857461 6.83072 0.0935412L6.73841 0L6.19482 0.550854L6.28713 0.644395C6.89739 1.26281 7.23585 2.08389 7.23585 2.95694C7.23585 3.82999 6.89739 4.65108 6.28713 5.26949L6.19482 5.36303Z"
                    fill="#353839"
                />
            </g>
            <defs>
                <clipPath id="clip0_1204_10703">
                    <rect width="8" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

