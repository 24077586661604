import { get as getProjection } from 'ol/proj.js';
import { getWidth } from 'ol/extent.js';

export const getTileInfo = (crs: string = 'EPSG:3857', size = 512) => {
    const projExtent = getProjection(crs)!.getExtent();
    const startResolution = getWidth(projExtent) / size;
    const resolutions = new Array(22);
    for (let i = 0, ii = resolutions.length; i < ii; ++i) {
        resolutions[i] = startResolution / Math.pow(2, i);
    }
    return { resolutions, origin: [projExtent[0], projExtent[3]], extent: projExtent };
};

export const captitalizeString = (source: string, allWords: boolean = false): string => {
    return allWords
        ? source
              .split(' ')
              .map(word => captitalizeString(word))
              .join(' ')
        : source.charAt(0).toUpperCase() + source.slice(1);
};

export function getNeighborPixels(x: number, y: number) {
    const neighbors = [];
    for (let i = -1; i <= 1; i++) {
        for (let j = -1; j <= 1; j++) {
            neighbors.push({ x: x + i, y: y + j });
        }
    }
    return neighbors.filter(v => v.x >= 0 || v.y >= 0).map(v => [v.x, v.y]);
}

export const sortByStringProp = (a: any, b: any, prop: string) => {
    let stringA = a[prop].toUpperCase();
    let stringB = b[prop].toUpperCase();
    if (stringA < stringB) return -1;
    if (stringA > stringB) return 1;
    return 0;
};

export namespace SelectionUtils {
    export const reservedKeys = ['id', 'idtipologia', 'idcategoria', 'idfunzione', 'idfondo'];
    const valueSuffixes: Record<string, string> = {
        lunghezza: 'm',
        frequenza: 'GHz'
    };

    export const transformKey = (key: string) =>
        captitalizeString(
            key
                .replace('unita_immobiliari', 'Numero UI')
                .replace('num_ui', 'Numero UI')
                .replace('srb_id', 'SRB ID')
                .replace('srb', 'SRB')
                .replace('roe', 'ROE')
                .replace('_', ' '),
            true
        );

    export const transformValue = (key: string, value: string | number | null) =>
        value ? `${value}${valueSuffixes[key] ?? ''}` : null;

    const featureLookupTable = {
        _area_copertura: 'Area di Copertura',
        _ui: 'Unità Immobiliare',
        _pozzetti: 'Pozzetto',
        _pac_pal: 'PAC PAL',
        _tratte: 'Tratta',
        _srb: 'SRB',
        _roe: 'ROE'
    };

    export const getFeatureTitle = (layerId: string, info: Record<string, number>) => {
        let title;

        for (let key in featureLookupTable)
            if (layerId.endsWith(key)) title = featureLookupTable[key as keyof typeof featureLookupTable];

        if (title) return title;

        if (layerId.endsWith('_nodi_rete')) {
            if ([6, 23, 40, 41, 42, 43, 50, 57, 58].includes(info.idcategoria)) return 'PCN';
            if ([11, 19, 55].includes(info.idcategoria)) return 'CNO';
            if ([21, 25, 26, 28].includes(info.idcategoria)) return 'Nodo Operatore';
        }

        return layerId;
    };
}

