import { FC } from 'react';
import { Switch as MUISwitch } from '@mui/material';
import classes from './Switch.module.scss';

type SwitchProps = {
    onClick: () => void;
    disabled?: boolean;
    checked: boolean;
};

export const Switch: FC<SwitchProps> = params => {
    return (
        <MUISwitch
            {...params}
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track
            }}
        />
    );
};
