import { FC } from 'react';

export const Check: FC = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.4608 27.8041C15.3787 27.8043 15.2974 27.7882 15.2216 27.7568C15.1458 27.7253 15.077 27.6791 15.0191 27.6208L8.33331 20.9358L9.21748 20.0516L15.4608 26.295L30.09 11.6666L30.9733 12.5508L15.9025 27.6208C15.8447 27.6791 15.7759 27.7253 15.7 27.7568C15.6242 27.7882 15.5429 27.8043 15.4608 27.8041Z"
                fill="#34DE8C"
            />
        </svg>
    );
};

