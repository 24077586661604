import { OutlineContainer, Snackbar } from 'components/containers';
import { FC, useEffect, useMemo, useState } from 'react';
import formClasses from './FormContainer.module.scss';
import { Back, Check, Cross } from 'components/icons';
import { Link, useNavigate } from 'react-router-dom';
import loginClasses from './Login.module.scss';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { baseURL } from 'helpers';
import PasswordInput from './passwordInput/PasswordInput';
import { RootState, resetPasswordActions } from 'store';
import { useDispatch, useSelector } from 'react-redux';

interface IChangePasswordForm {
    password: string;
    confirmPassword: string;
}

const wrongCredentialsText = 'Le due password non coincidono';

const ChangePasswordForm: FC = () => {
    const [requestInProgress, setRequestInProgress] = useState(false);
    const [errorText, setErrorText] = useState('');
    const { token } = useSelector((x: RootState) => x.resetPassword);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { control, handleSubmit, formState, watch } = useForm({
        defaultValues: {
            password: '',
            confirmPassword: ''
        }
    });
    const isDisabled = useMemo(() => {
        if (requestInProgress) return true;
        return !formState.isValid;
    }, [requestInProgress, formState]);
    const errorBar = useMemo(() => {
        if (!errorText.length) return <></>;
        return <Snackbar text={errorText} isAlert={true} isOpen={errorText.length > 0} setIsOpen={() => setErrorText('')} />;
    }, [errorText]);
    const values = watch();
    const passwordCheck = useMemo(() => {
        let checks: any[] = [];
        if (!values.password)
            return [
                <span>
                    <Cross /> Almeno 8 caratteri
                </span>,
                <span>
                    <Cross /> Almeno una lettera MAIUSCOLA
                </span>,
                <span>
                    <Cross /> Almeno una lettera minuscola
                </span>,
                <span>
                    <Cross /> Almeno un numero
                </span>,
                <span>
                    <Cross /> Le password devono coincidere
                </span>
            ];
        const containNumbers = /(?=.*\d)/.test(values.password);
        const containUpper = /(?=.*[A-Z])/.test(values.password);
        const containLower = /(?=.*[a-z])/.test(values.password);
        const minLenght = /.{8,}/.test(values.password);
        checks.push(
            minLenght ? (
                <span className={formClasses.check}>
                    <Check /> Almeno 8 caratteri
                </span>
            ) : (
                <span className={formClasses.error}>
                    <Cross fill="#F44545" /> Almeno 8 caratteri
                </span>
            )
        );
        checks.push(
            containUpper ? (
                <span className={formClasses.check}>
                    <Check /> Almeno una lettera MAIUSCOLA
                </span>
            ) : (
                <span className={formClasses.error}>
                    <Cross fill="#F44545" /> Almeno una lettera MAIUSCOLA
                </span>
            )
        );
        checks.push(
            containLower ? (
                <span className={formClasses.check}>
                    <Check /> Almeno una lettera minuscola
                </span>
            ) : (
                <span className={formClasses.error}>
                    <Cross fill="#F44545" /> Almeno una lettera minuscola
                </span>
            )
        );
        checks.push(
            containNumbers ? (
                <span className={formClasses.check}>
                    <Check /> Almeno un numero
                </span>
            ) : (
                <span className={formClasses.error}>
                    <Cross fill="#F44545" /> Almeno un numero
                </span>
            )
        );
        checks.push(
            values.password === values.confirmPassword ? (
                <span className={formClasses.check}>
                    <Check /> Le password devono coincidere
                </span>
            ) : (
                <span className={formClasses.error}>
                    <Cross fill="#F44545" /> Le password devono coincidere
                </span>
            )
        );

        return checks;
    }, [values]);

    const onSubmit: SubmitHandler<IChangePasswordForm> = async data => {
        if (data.confirmPassword !== data.password) return setErrorText(wrongCredentialsText);
        setRequestInProgress(true);
        const res = await fetch(`${baseURL}/api/v1/login/change-password`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                Authorization: token!
            },
            body: JSON.stringify({
                password: data.password
            })
        });
        setRequestInProgress(false);
        if (res.status === 200) {
            dispatch(resetPasswordActions.setToken(undefined));
        } else setErrorText('Servizio non disponibile');
    };

    useEffect(() => {
        if (!token) navigate('/login');
    }, [token, navigate]);

    return (
        <div className={loginClasses.rootContainer}>
            <div></div>
            <OutlineContainer className={formClasses.rootContainer}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={formClasses.formContainer}>
                        <div className={formClasses.resetPasswordHeader}>
                            <Link to={'/login'}>
                                <Back />
                            </Link>
                            <b>Reset password</b>
                        </div>
                        <div className={formClasses.inputBox}>
                            <label htmlFor="username">Nuova Password</label>
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: 'Password Richiesta', pattern: /(?=.*\d)(?=.*[A-Z]).{8,}/ }}
                                render={({ field }) => (
                                    <PasswordInput
                                        error={errorText === wrongCredentialsText || !!formState.errors.password}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className={formClasses.inputBox}>
                            <label htmlFor="token">Ripeti nuova password</label>
                            <Controller
                                name="confirmPassword"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <PasswordInput
                                        error={errorText === wrongCredentialsText || !!formState.errors.confirmPassword}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className={formClasses.pattern}>
                        <span>
                            <b>La password deve rispettare questi criteri:</b>
                        </span>
                        {passwordCheck}
                    </div>
                    <button type="submit" className={`${formClasses.actionButton}`} disabled={isDisabled}>
                        <b>Aggiorna password</b>
                    </button>
                </form>
            </OutlineContainer>
            <div className={loginClasses.errors}>{errorBar}</div>
        </div>
    );
};
export default ChangePasswordForm;

