import { FC } from 'react';

export const CloseSelection: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path
                d="M23.8029 7.20845L16.7915 0.197106C16.5287 -0.065702 16.0594 -0.065702 15.7966 0.197106L9.36719 6.6359L10.3621 7.63082L13.4219 4.57098L14.445 3.54791L16.3034 1.68948L22.3199 7.70591L20.4614 9.56433L19.4384 10.5874L16.3785 13.6472L17.3734 14.6422L23.8122 8.20336C23.9436 8.07196 24.0187 7.89363 24.0187 7.70591C24.0187 7.51819 23.9436 7.33985 23.8122 7.20845H23.8029Z"
                fill="#0050DE"
            />
            <path
                d="M16.3692 15.6371L15.3743 14.6422L9.35784 8.63514L8.36292 7.64023L0.994916 0.272217L0 1.26713L7.35862 8.62576L2.46852 13.5159C2.36527 13.6191 2.29957 13.7505 2.27141 13.9007L0.872898 22.3105C0.835354 22.5358 0.910442 22.7611 1.07 22.9206C1.20141 23.052 1.37974 23.1271 1.56746 23.1271C1.60501 23.1271 1.64255 23.1271 1.68009 23.1271L10.0993 21.7192C10.2495 21.6911 10.3809 21.6254 10.4842 21.5221L15.3743 16.632L22.7423 24L23.7372 23.0051L16.3692 15.6371ZM9.63942 20.377L2.42159 21.5784L3.623 14.3606L8.35354 9.63006L14.37 15.6465L9.63942 20.377Z"
                fill="#0050DE"
            />
        </svg>
    );
};
