import { FC } from 'react';

export const Close: FC = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.0002 1.11939L16.8808 0L9.00077 7.88113L1.11963 0L0.000233687 1.11939L7.8782 9.00053L-0.00292969 16.8806L1.11647 18L8.9976 10.1189L16.8777 18L17.9971 16.8806L10.1191 9.00053L18.0002 1.11939Z"
                fill="#0050DE"
            />
        </svg>
    );
};

