import { FC } from 'react';

const Map: FC = () => {
    return (
        <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.6621 0.0824379C21.5599 0.0254373 21.4445 -0.00289457 21.3278 0.00037496C21.2112 0.00364449 21.0976 0.0383965 20.9986 0.101034L14.4469 4.24176L7.89963 0.101034C7.79563 0.0350172 7.67536 0 7.55258 0C7.42981 0 7.30953 0.0350172 7.20553 0.101034L0.308955 4.4596C0.214363 4.51871 0.136308 4.60134 0.0822211 4.69961C0.0281344 4.79789 -0.000152768 4.90854 6.20506e-07 5.02102V23.3381C2.88879e-06 23.4563 0.0312464 23.5723 0.0905019 23.6742C0.149757 23.776 0.234822 23.8599 0.336949 23.9173C0.43939 23.973 0.554499 24.0005 0.670746 23.9973C0.786993 23.994 0.900343 23.96 0.999575 23.8987L7.55039 19.758L14.1012 23.8996C14.2052 23.9652 14.3253 24 14.4478 24C14.5704 24 14.6905 23.9652 14.7944 23.8996L21.6927 19.5392C21.7874 19.4803 21.8655 19.3977 21.9193 19.2994C21.9731 19.201 22.0009 19.0902 22 18.9778V0.661573C21.9998 0.543336 21.9684 0.427297 21.909 0.325473C21.8496 0.223648 21.7643 0.139741 21.6621 0.0824379ZM6.89653 7.46153V18.6112L1.30767 22.1444V5.39029L7.55039 1.44261L13.7931 5.39029V16.5382H15.1017V5.39029L20.6922 1.85526V18.6094L14.4469 22.5571L8.20511 18.6094V7.46153H6.89653Z"
                fill="#0050DE"
            />
        </svg>
    );
};

export default Map;
