import GeoJSON, { GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import colors from 'styles/modules/colors.module.scss';
import { statsMaxZoom } from 'helpers';

const r = 15;
const offset = 4;
const x = Math.cos(Math.PI / 6) * r + offset;
const y = Math.sin(Math.PI / 6) * r + offset;

const detailedStyle = {
    concession: {
        r,
        color: colors.concessione,
        displacement: [0, r + offset],
        offsetX: 0,
        offsetY: -r - offset
    },
    fwa: {
        r,
        color: colors.fwa,
        displacement: [x, -y],
        offsetX: x,
        offsetY: y
    },
    direct: {
        r,
        color: colors.diretto,
        displacement: [-x, -y],
        offsetX: -x,
        offsetY: y
    }
};

export const createStatsLayers = (collection: GeoJSONFeatureCollection) => {
    const features = new GeoJSON().readFeatures(collection);
    const detailedFeatures = features
        .map(value => {
            const fwa = value.clone();
            fwa.setProperties({ type: 'fwa', count: value.getProperties().count.fwa });
            const concession = value.clone();
            concession.setProperties({ type: 'concession', count: value.getProperties().count.concession });
            const direct = value.clone();
            direct.setProperties({ type: 'direct', count: value.getProperties().count.direct });
            return [fwa, concession, direct];
        })
        .flat();
    const source = new VectorSource({ features });
    const detailedSource = new VectorSource({ features: detailedFeatures });
    const generalLayer = new VectorLayer({
        source,
        maxZoom: 7,
        zIndex: 9999,
        style: feature => {
            let total = 0;
            const count = feature.get('count');
            for (const key in count) total += count[key];
            return new Style({
                image: new CircleStyle({
                    radius: r,
                    fill: new Fill({
                        color: colors.g800
                    })
                }),
                text: new Text({
                    text: total.toString(),
                    fill: new Fill({ color: colors.white }),
                    font: 'bold 13px Titillium Web'
                })
            });
        },
        properties: { id: 'generalStats', overlay: true }
    });
    const detailedLayer = new VectorLayer({
        source: detailedSource,
        minZoom: 7,
        maxZoom: statsMaxZoom,
        zIndex: 9999,
        style: feature => {
            const count = feature.get('count');
            const { r, color, displacement, offsetX, offsetY } =
                detailedStyle[feature.get('type') as 'fwa' | 'concession' | 'direct'];
            return new Style({
                image: new CircleStyle({
                    radius: r,
                    fill: new Fill({
                        color
                    }),
                    displacement
                }),
                text: new Text({
                    text: count.toString(),
                    fill: new Fill({ color: colors.white }),
                    font: 'bold 13px Titillium Web',
                    offsetX,
                    offsetY
                })
            });
        },
        properties: { id: 'detailedStats', overlay: true }
    });
    return [generalLayer, detailedLayer];
};
