import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { SelectionUtils, baseURL, getNeighborPixels, getTargetZoom, map, modelTitles, statsMaxZoom } from 'helpers';
import { mapActions, popupActions, searchActions } from 'store';
import { Style, Fill, Stroke } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Layer from 'ol/layer/Layer';
import TileWMS from 'ol/source/TileWMS';
import Draw from 'ol/interaction/Draw';
import { GeoJSON } from 'ol/format';
import { Geometry } from 'ol/geom';
import OlMap from 'ol/Map';

let selection: Feature<Geometry> | undefined;
const style = new Style({
    stroke: new Stroke({
        color: '#0023B5',
        width: 2
    }),
    fill: new Fill({
        color: 'rgba(0, 134, 255, 0.3)'
    })
});

export const selectionLayer = new VectorLayer({
    source: undefined,
    style,
    properties: { id: 'selection', overlay: true }
});

let abortController = new AbortController();

const cleanAbortController = () => {
    abortController.abort('abort');
    abortController = new AbortController();
};

export const registerSelectionHandler = (dispatch: Dispatch<AnyAction>) => {
    map.on('singleclick', async event => {
        const olMap = map as OlMap;
        const isDrawing = !!olMap
            .getInteractions()
            .getArray()
            .find(interaction => interaction instanceof Draw);
        if (isDrawing) return;

        dispatch(popupActions.setLoading(true));
        dispatch(popupActions.setOpen(false));
        cleanAbortController();

        let selected: { feature: Feature; layer: Layer } | undefined = undefined;
        const zoom = map.getView().getZoom()!;

        const viewResolution = olMap.getView().getResolution()!;
        const layers = [...olMap.getAllLayers()].sort((a: Layer, b: Layer) => (a.getZIndex() ?? 0) - (b.getZIndex() ?? 0));

        let aborted;

        for (let i = layers.length - 1; !aborted && !selected && i >= 0; i--) {
            if (layers[i].getProperties().overlay || !layers[i].isVisible()) continue;
            const pixels = getNeighborPixels(event.pixel[0], event.pixel[1]);
            const hit = pixels.some(p => {
                const data = layers[i].getData(event.pixel) as any;
                return data && (data[3] as any) > 0;
            });
            if (!hit) continue;
            const source = layers[i].getSource();
            if (source instanceof TileWMS) {
                const url = source.getFeatureInfoUrl(event.coordinate, viewResolution, 'EPSG:3857', {
                    INFO_FORMAT: 'application/json'
                })!;
                let featureCollection: any;
                try {
                    featureCollection = await fetch(url.replace('gwc/service', ''), { signal: abortController.signal }).then(
                        res => res.json()
                    );
                    if (featureCollection.features.length === 0) continue;
                    const feature = new GeoJSON().readFeature(featureCollection.features[0]);
                    if (zoom < statsMaxZoom && zoom < (await getTargetZoom(zoom, feature.getGeometry()!))) {
                        const typeName = layers[i].getProperties().name;
                        const id = featureCollection.features[0].properties.id;
                        const wfsRequestBase = `${baseURL}/geoserver/wfs`;
                        const search = new URLSearchParams({
                            service: 'wfs',
                            version: '2.0.0',
                            request: 'GetFeature',
                            typeNames: typeName,
                            outputFormat: 'application/json',
                            srsName: 'EPSG:3857',
                            cql_filter: `id=${id}`
                        });
                        const wfsCollection = await fetch(`${wfsRequestBase}?${search.toString()}`, {
                            signal: abortController.signal
                        }).then(res => res.json());
                        selected = { feature: new GeoJSON().readFeature(wfsCollection.features[0]), layer: layers[i] };
                    } else {
                        selected = { feature: new GeoJSON().readFeature(featureCollection.features[0]), layer: layers[i] };
                    }
                } catch (error: any) {
                    if (error instanceof DOMException && error.name === 'AbortError') return (aborted = true);
                    console.error(error);
                }
            }
        }

        if (!selected) {
            selection = undefined;
            selectionLayer.setSource(null);
            selectionLayer.changed();
            return dispatch(popupActions.setLoading(false));
        }

        selection = selected.feature;
        const source = new VectorSource({ features: [selection] });
        selectionLayer.setSource(source);
        selectionLayer.setZIndex(selected.layer.getZIndex()! + 1);
        selectionLayer.changed();

        const featureProps = selected.feature.getProperties();
        const layerId = selected.layer.getProperties().name;
        if (layerId === 'webgis:istat_regioni') {
            dispatch(searchActions.resetSearch());
            dispatch(searchActions.setRegionId(featureProps.id));
        }
        if (layerId === 'webgis:istat_province') {
            dispatch(searchActions.resetSearch());
            dispatch(searchActions.setRegionId(featureProps.regione_id));
            dispatch(searchActions.setProvinceId(featureProps.id));
        }

        if (zoom < statsMaxZoom) return dispatch(mapActions.zoomOnGeometry(selected.feature.getGeometry()!));

        delete featureProps.geometry;

        const title = SelectionUtils.getFeatureTitle(layerId, featureProps);

        const groupId = selected.layer.getProperties().groupId;
        const targetZoom = await getTargetZoom(zoom, selected.feature.getGeometry()!);
        if (groupId === 'webgis' && zoom < targetZoom) {
            selectionLayer.set('keepSelection', true);
            dispatch(mapActions.zoomOnGeometry(selected.feature.getGeometry()!));
            dispatch(popupActions.setLoading(false));
            return dispatch(popupActions.setOpen(false));
        } else {
            selectionLayer.set('keepSelection', false);
        }
        if (!Object.keys(modelTitles).includes(groupId)) {
            dispatch(popupActions.setLoading(false));
            return dispatch(popupActions.setOpen(false));
        }

        let info: Record<string, string | null> = {};
        for (let key in featureProps)
            if (!SelectionUtils.reservedKeys.includes(key))
                info[SelectionUtils.transformKey(key)] = SelectionUtils.transformValue(key, featureProps[key]);

        const infoStyle = document.getElementById('info')!.style;
        const navbarHeigt = document.getElementById('navbar')!.offsetHeight;
        infoStyle.left = event.pixel[0] + 'px';
        infoStyle.top = event.pixel[1] + navbarHeigt + 'px';
        dispatch(popupActions.setTitle(title));
        dispatch(popupActions.setModel(groupId));
        dispatch(popupActions.setInfo(info));
        dispatch(popupActions.setLoading(false));
        dispatch(popupActions.setOpen(true));
    });
    map.on('movestart', () => {
        cleanAbortController();
        if (!selectionLayer.get('keepSelection')) {
            selectionLayer.setSource(null);
            selectionLayer.changed();
        }
        dispatch(popupActions.setLoading(false));
        dispatch(popupActions.setOpen(false));
    });
};

