import { FC } from 'react';

type ArrowProps = {
    direction: 'up' | 'down';
};

export const Arrow: FC<ArrowProps> = ({ direction = 'up' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="9"
            viewBox="0 0 16 9"
            fill="none"
            style={{ transform: direction === 'up' ? 'rotate(180deg)' : '' }}
        >
            <path
                d="M15.061 1.061L8.06103 8.061C7.99138 8.13069 7.90868 8.18598 7.81766 8.2237C7.72663 8.26142 7.62907 8.28084 7.53054 8.28084C7.432 8.28084 7.33444 8.26142 7.24341 8.2237C7.15239 8.18598 7.06968 8.13069 7.00003 8.061L3.37011e-05 1.061L1.06103 1.22392e-06L7.53103 6.47L14 9.27556e-08L15.061 1.061Z"
                fill="#0050DE"
            />
        </svg>
    );
};
