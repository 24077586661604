import { FC, PropsWithChildren } from 'react';
import classes from './OutlineContainer.module.scss';

type OutlineContainerProps = {
    draggable?: boolean;
    onClick?: () => void;
    className?: string;
    extraClasses?: string;
    style?: React.CSSProperties;
    onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
    onMouseUp?: React.MouseEventHandler<HTMLDivElement>;
    onTouchStart?: React.TouchEventHandler<HTMLDivElement>;
    onTouchEnd?: React.TouchEventHandler<HTMLDivElement>;
};

export const OutlineContainer: FC<PropsWithChildren<OutlineContainerProps>> = ({
    children,
    onClick,
    className: baseClasses,
    extraClasses,
    style,
    onMouseDown,
    onMouseUp,
    onTouchStart,
    onTouchEnd
}) => {
    return (
        <div
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            style={style}
            className={`${classes.container} ${baseClasses ?? ''} ${extraClasses ?? ''}`}
            onClick={onClick}
        >
            {children}
        </div>
    );
};
