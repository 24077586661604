import { FC } from 'react';
import { Undo } from 'components/icons';
import classes from './UndoButton.module.scss';

type UndoProps = {
    onClick: () => void;
    disabled?: boolean;
};

const UndoButton: FC<UndoProps> = ({ onClick, disabled }) => {
    return (
        <div
            className={`${classes.button} ${disabled ? classes.disabled : ''}`}
            onClick={() => {
                !disabled && onClick();
            }}
        >
            <Undo />
        </div>
    );
};

export default UndoButton;
