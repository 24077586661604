import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, CircularProgress, TextField } from '@mui/material';
import { FC, useMemo } from 'react';
import classes from './AutoCompleteInput.module.scss';

interface Option {
    id: number | string;
    label: string;
}

type AutocompleteInputProps = {
    id: string;
    label: string;
    placeholder: string;
    additionalInfo?: string;
    options: Option[];
    disabled?: boolean;
    onChange?: (
        event: React.SyntheticEvent<Element, Event>,
        value: Option,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<Option> | undefined
    ) => void;
    value?: Option;
    loading?: boolean;
};

const AutoCompleteInput: FC<AutocompleteInputProps> = ({
    id,
    label,
    options,
    additionalInfo,
    disabled,
    onChange,
    value,
    loading,
    placeholder
}) => {
    const optionsArray = useMemo(() => (loading ? [] : options), [loading, options]);
    return (
        <div className={`text-input ${disabled || loading ? classes.disabled : ''}`}>
            <label htmlFor={id}>
                {label} <span>{additionalInfo}</span>
            </label>
            <Autocomplete
                id={id}
                key={options.toString()}
                autoComplete
                disabled={disabled || loading}
                disableClearable
                classes={{ inputRoot: classes.inputRoot }}
                options={optionsArray}
                noOptionsText="Nessun risultato"
                renderInput={params => (
                    <TextField
                        {...params}
                        fullWidth
                        InputProps={{
                            ...params.InputProps,
                            placeholder: loading ? 'Caricamento in corso...' : placeholder,
                            endAdornment: loading ? (
                                <div className={classes.loadingIcon}>
                                    <CircularProgress size={'1.5rem'} />
                                </div>
                            ) : (
                                <></>
                            ),
                            sx: { padding: '0 2px !important' },
                            type: 'search'
                        }}
                    />
                )}
                onChange={onChange}
                value={value}
                isOptionEqualToValue={(option: Option, value: Option) => option.id === value.id}
            />
        </div>
    );
};

export default AutoCompleteInput;
