import colors from 'styles/modules/colors.module.scss';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Snap, Modify, Draw } from 'ol/interaction';
import { Style, Fill, Stroke, Circle } from 'ol/style';
import { Geometry, MultiPoint } from 'ol/geom';
import Collection from 'ol/Collection';
import { Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import { area, kinks } from '@turf/turf';

export type DrawAlertState = 'ok' | 'area-limit' | 'self-intersection';
export type DrawStatus = { alert: boolean; detail: DrawAlertState };

const defaultDrawStyleFunction = new Draw({ type: 'Polygon' }).getOverlay().getStyleFunction();
const halfRed = [255, 0, 0, 0.5];

export const redPolygonStyle = new Style({
    fill: new Fill({
        color: halfRed
    })
});

const checkPolygonSelfIntersection = (polygon: any) => {
    const kinksCollection = kinks(polygon);
    if (kinksCollection.features.length === 0) return false;
    if (kinksCollection.features.length === 1) {
        const polygonGeometry: [number, number][] = polygon.geometry.coordinates[0];
        const firstPoint = polygonGeometry[0];
        const lastPoint = polygonGeometry[polygonGeometry.length - 2];
        const kinkPoint = kinksCollection.features[0].geometry.coordinates;
        if (firstPoint[0] === kinkPoint[0] && firstPoint[1] === kinkPoint[1]) return false;
        if (lastPoint[0] === kinkPoint[0] && lastPoint[1] === kinkPoint[1]) return false;
    }
    return true;
};

export const checkAndDispatchDrawAlert = (geoJSON: string, propagateEvent = true): DrawStatus => {
    const polygon = JSON.parse(geoJSON);
    if (checkPolygonSelfIntersection(polygon)) {
        propagateEvent && window.dispatchEvent(new CustomEvent<DrawAlertState>('drawAlert', { detail: 'self-intersection' }));
        return { alert: true, detail: 'self-intersection' };
    }
    const polygonArea = area(polygon);
    const drawAlert = Math.floor(polygonArea) > 51219242239;
    const detail = drawAlert ? 'area-limit' : 'ok';
    propagateEvent && window.dispatchEvent(new CustomEvent<DrawAlertState>('drawAlert', { detail }));
    return { alert: drawAlert, detail };
};

const drawSource = new VectorSource({ wrapX: false });
const drawLayer = new VectorLayer({
    source: drawSource,
    properties: { id: 'draw', overlay: true },
    zIndex: 1000
});
drawLayer.setStyle(feature => {
    const featureType = feature.getGeometry()?.getType()!;
    let drawAlert: boolean = false;
    if (featureType === 'Polygon') {
        const transformedFeature = (feature as Feature).clone().getGeometry()?.transform('EPSG:3857', 'EPSG:4326');
        const geoJSON = new GeoJSON().writeFeature(new Feature(transformedFeature));
        drawAlert = checkAndDispatchDrawAlert(geoJSON).alert;
    }
    const fill = new Fill({
        color: drawAlert ? halfRed : 'rgba(255,255,255,0.4)'
    });
    const vertexFill = new Fill({
        color: colors.p700
    });
    const stroke = new Stroke({
        color: colors.p700,
        width: 1.25
    });
    const styles = [
        new Style({
            fill,
            stroke
        }),
        new Style({
            image: new Circle({
                radius: 3,
                fill: vertexFill,
                stroke
            }),
            geometry: e => {
                const coordinates = (e.getGeometry()! as any).getCoordinates()[0];
                return new MultiPoint(coordinates);
            }
        })
    ];
    return styles;
});
const draw = new Draw({
    source: drawSource,
    type: 'Polygon',
    style: (feature, resolution) => {
        const featureType = feature.getGeometry()?.getType()!;
        if (featureType === 'Polygon') {
            const transformedFeature = (feature as Feature).clone().getGeometry()?.transform('EPSG:3857', 'EPSG:4326');
            const geoJSON = new GeoJSON().writeFeature(new Feature(transformedFeature));
            if (checkAndDispatchDrawAlert(geoJSON).alert) return redPolygonStyle;
        }
        return defaultDrawStyleFunction ? defaultDrawStyleFunction(feature, resolution) : undefined;
    },
    features: new Collection<Feature<Geometry>>([])
});
const snap = new Snap({ source: drawSource });
const modify = new Modify({ source: drawSource });

export { drawSource, drawLayer, draw, snap, modify };

