import { Close, Download, Info, Layer, Search } from 'components/icons';
import Map from 'components/icons/Map';
import { MenuOpen } from 'components/icons';
import { LeftToolBarTypes } from 'helpers';
import { useState } from 'react';
import classes from './LeftToolbar.module.scss';
import DownloadProject from './downloadProject/DownloadProject';
import { LayerInfo } from './layerInfo/LayerInfo';
import LayerTool from './layerTool/LayerTool';
import ProjectFinder from './projectFinder/ProjectFinder';
import TileSelector from './tileSelector/TileSelector';
import ToolsPageMobile from './toolsPageMobile/ToolsPageMobile';

const LeftToolBar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<LeftToolBarTypes>(LeftToolBarTypes.none);
    const [isOpenSearch, setIsOpenSearch] = useState<boolean>(false);
    const [isOpenLayer, setIsOpenLayer] = useState<boolean>(false);
    const [isOpenDownload, setIsOpenDownload] = useState<boolean>(false);
    const [isOpenMapStyle, setIsOpenMapStyle] = useState<boolean>(false);
    const [isOpenLegend, setIsOpenLegend] = useState<boolean>(false);

    function handleChangeActiveTab(tab: LeftToolBarTypes) {
        if (activeTab === tab) {
            setActiveTab(LeftToolBarTypes.none);
            return;
        }
        setActiveTab(tab);
    }

    return (
        <>
            <div className={classes.mobileMenuContainer}>
                <div
                    onClick={() => {
                        setIsMenuOpen(!isMenuOpen);
                    }}
                    className={classes.menuButton}
                >
                    {isMenuOpen ? <Close /> : <MenuOpen />}
                </div>
                {isMenuOpen && (
                    <div className={classes.mainToolsButtons}>
                        <div
                            className={classes.searchButton}
                            onClick={() => {
                                setIsOpenSearch(!isOpenSearch);
                            }}
                        >
                            <Search />
                        </div>
                        <div
                            className={classes.layerButton}
                            onClick={() => {
                                setIsOpenLayer(!isOpenLayer);
                            }}
                        >
                            <Layer />
                        </div>
                        <div
                            className={classes.downloadButton}
                            onClick={() => {
                                setIsOpenDownload(!isOpenDownload);
                            }}
                        >
                            <Download />
                        </div>
                        <div
                            onClick={() => {
                                setIsOpenMapStyle(!isOpenMapStyle);
                            }}
                            className={classes.mapStyleButton}
                        >
                            <Map />
                        </div>
                    </div>
                )}
            </div>
            {(isOpenSearch || isOpenLayer || isOpenDownload || isOpenMapStyle || isOpenLegend) && (
                <div className={classes.closeButton}>
                    <div
                        onClick={() => {
                            setIsOpenSearch(false);
                            setIsOpenLayer(false);
                            setIsOpenDownload(false);
                            setIsOpenMapStyle(false);
                            setIsOpenLegend(false);
                        }}
                    >
                        <Close />
                    </div>
                    <b>
                        {(isOpenSearch && 'Cerca') ||
                            (isOpenLayer && 'Layer') ||
                            (isOpenDownload && 'Download') ||
                            (isOpenMapStyle && 'Stile mappa') ||
                            (isOpenLegend && 'Legenda')}
                    </b>
                </div>
            )}
            {isOpenSearch && (
                <div
                    className={classes.showAreaButton}
                    onClick={() => {
                        setIsOpenSearch(false);
                    }}
                >
                    <div>
                        <Search />
                    </div>
                    <b>Visualizza Area</b>
                </div>
            )}
            {
                <div onClick={() => setIsOpenLegend(true)} className={classes.legendButton}>
                    <Info />
                </div>
            }
            <div className={`${classes.leftToolbar} ${!isMenuOpen ? classes.openLeftToolBarMobile : ''}`}>
                <div className={classes.mainTools}>
                    <ProjectFinder activeTab={activeTab} setActiveTab={handleChangeActiveTab} />
                    <LayerTool activeTab={activeTab} setActiveTab={handleChangeActiveTab} />
                    <DownloadProject activeTab={activeTab} setActiveTab={handleChangeActiveTab} />
                </div>
            </div>
            {isOpenSearch && <ToolsPageMobile tool={'search'} />}
            {isOpenLayer && <ToolsPageMobile tool="layer" />}
            {isOpenDownload && <ToolsPageMobile tool="download" />}
            {isOpenMapStyle && <ToolsPageMobile tool="mapStyle" />}
            {isOpenLegend && <ToolsPageMobile tool="legend" />}
            <div className={classes.tileSelector}>
                <TileSelector />
                <LayerInfo />
            </div>
        </>
    );
};

export default LeftToolBar;

