import { OutlineContainer, Snackbar } from 'components/containers';
import { FC, useMemo, useState } from 'react';
import formClasses from './FormContainer.module.scss';
import { OutlinedInput } from '@mui/material';
import { Back } from 'components/icons';
import { Link, useNavigate } from 'react-router-dom';
import loginClasses from './Login.module.scss';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { baseURL } from 'helpers';
import { useDispatch } from 'react-redux';
import { resetPasswordActions } from 'store';

interface IResetPasswordForm {
    username: string;
    token: string;
}

const ResetPasswordForm: FC = () => {
    const navigate = useNavigate();
    const [requestInProgress, setRequestInProgress] = useState(false);
    const [wrongCredentials, setWrongCredentials] = useState(false);
    const dispatch = useDispatch();
    const { control, handleSubmit, formState } = useForm({
        defaultValues: {
            username: '',
            token: ''
        }
    });
    const isDisabled = useMemo(() => {
        if (requestInProgress) return true;
        return !formState.isValid;
    }, [requestInProgress, formState]);
    const errorBar = useMemo(() => {
        if (!wrongCredentials) return <></>;
        return (
            <Snackbar
                text="Username e/o Codice errati"
                isAlert={true}
                isOpen={wrongCredentials}
                setIsOpen={setWrongCredentials}
            />
        );
    }, [wrongCredentials]);

    const onSubmit: SubmitHandler<IResetPasswordForm> = async data => {
        setRequestInProgress(true);
        const res = await fetch(`${baseURL}/api/v1/login/reset-password`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                username: data.username,
                token: data.token
            })
        });
        setRequestInProgress(false);
        if (res.status === 200) {
            const resBody = await res.json();
            dispatch(resetPasswordActions.setToken(resBody.token));
            navigate('/login/change-password');
        } else setWrongCredentials(true);
    };

    return (
        <div className={loginClasses.rootContainer}>
            <div></div>
            <OutlineContainer className={formClasses.rootContainer}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={formClasses.formContainer}>
                        <div className={formClasses.resetPasswordHeader}>
                            <Link to={'/login'}>
                                <Back />
                            </Link>
                            <b>Reset Password</b>
                        </div>
                        <div className={formClasses.inputBox}>
                            <label htmlFor="username">Username</label>
                            <Controller
                                name="username"
                                control={control}
                                rules={{ required: 'Username Richiesto' }}
                                render={({ field }) => (
                                    <OutlinedInput
                                        classes={{ root: formClasses.outlinedInput }}
                                        autoComplete="username"
                                        error={wrongCredentials || !!formState.errors.username}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className={formClasses.inputBox}>
                            <label htmlFor="token">Codice di autenticazione</label>
                            <Controller
                                name="token"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <OutlinedInput
                                        classes={{ root: formClasses.outlinedInput }}
                                        autoComplete="off"
                                        error={wrongCredentials || !!formState.errors.token}
                                        type="text"
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <button type="submit" className={`${formClasses.actionButton}`} disabled={isDisabled}>
                        <b>Verifica Codice</b>
                    </button>
                </form>
            </OutlineContainer>
            <div className={loginClasses.errors}>{errorBar}</div>
        </div>
    );
};
export default ResetPasswordForm;

