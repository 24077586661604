import { CircularProgress } from '@mui/material';
import { GridCallbackDetails, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { Table } from 'components/Table';
import { OutlineContainer } from 'components/containers';
import { Close, TableIcon, ResizeBig, ResizeSmall } from 'components/icons';
import {
    SelectionUtils,
    modelEntities,
    modelTitles,
    alternativeModelTitles,
    onlyTouchInput,
    Result,
    ResultEntity
} from 'helpers';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import classes from './AttributeTable.module.scss';
import { TableContext } from 'context';

const maxWidths: Record<string, number> = {
    num_ui: 90,
    unita_immobiliari: 90,
    civico: 70
};

const getPage = (data: Result, page: number, pageSize = 100) => {
    let startIndex = page * pageSize;
    if (startIndex > 0) startIndex++;
    if (startIndex > data.extraction.length) return [];
    let lastIndexInPage = Math.min(data.extraction.length, (page + 1) * pageSize);
    const pageData = data.extraction.slice(startIndex, lastIndexInPage);
    return pageData;
};

export const AttributesTable = () => {
    const { tableData: data, drawAlert, requestTableEntity } = useContext(TableContext);
    const [isMobileView, setIsMobileView] = useState<boolean>(onlyTouchInput);
    const { polygon, drawingMode, isDrawing } = useSelector((root: RootState) => root.draw);
    const [isOpen, setIsOpen] = useState<boolean>(!onlyTouchInput);
    const [selectedModel, setSelectedModel] = useState<keyof typeof modelEntities>('fwa');
    const [selectedEntity, setSelectedEntity] = useState<string>('srb');
    const [offsets, setOffsets] = useState<Record<string, number>>({});
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

    useEffect(() => {
        function handleResize() {
            setIsMobileView(window.matchMedia('(max-width: 87.5em)').matches);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setSelectedEntity(modelEntities[selectedModel][0].id);
    }, [selectedModel]);

    const isFetching = useMemo(() => {
        if (!data) return false;
        if (Object.keys(data).length === 0) {
            setOffsets({});
            setSelectedModel('fwa');
            setSelectedEntity('srb');
            return true;
        }
        return false;
    }, [data]);

    const isFetchingEntity = useMemo(() => {
        if (!data) return true;
        const arr = data[`${selectedModel}_${selectedEntity}`];
        if (!arr) return true;
        if (arr.rowCount === 0) return false;
        const offset = offsets[`${selectedModel}_${selectedEntity}`] ?? 0;
        if (arr.extraction.length - 1 < offset) return true;
        return false;
    }, [data, offsets, selectedModel, selectedEntity]);

    useEffect(() => {
        if (!isOpen && !onlyTouchInput && isFetching) setIsOpen(true);
    }, [isFetching, isOpen]);

    const toggleOpen = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    const toggleFullScreen = useCallback(() => {
        setIsFullScreen(prev => !prev);
    }, [setIsFullScreen]);

    const models = useMemo(
        () =>
            Object.keys(modelTitles).map(key => (
                <div
                    key={key}
                    className={`${classes.modelButton} ${key === selectedModel ? classes.selected : ''}`}
                    onClick={() => setSelectedModel(key as keyof typeof modelTitles)}
                >
                    <div className={classes.modelTitles}>{modelTitles[key as keyof typeof modelTitles]}</div>
                    <div className={classes.alternativeModelTitles}>
                        {alternativeModelTitles[key as keyof typeof modelTitles]}
                    </div>
                </div>
            )),
        [selectedModel]
    );

    const entities = useMemo(
        () =>
            modelEntities[selectedModel].map(el => (
                <div
                    key={el.id}
                    className={`${classes.entityButton} ${el.id === selectedEntity ? classes.selected : ''}`}
                    onClick={() => setSelectedEntity(el.id)}
                >
                    {el.label}
                </div>
            )),
        [selectedEntity, selectedModel]
    );

    const getColumnFlexValues = useCallback((arr: Record<string, string | number | null>[]) => {
        const res: Record<string, number> = {};
        let sum = 0;
        if (!arr || !Array.isArray(arr) || arr.length === 0) return res;
        const keys = Object.keys(arr[0]).filter(key => !SelectionUtils.reservedKeys.includes(key));
        keys.forEach(key => {
            const maxValue = Math.max(...arr.map(el => el[key]?.toString().length || 0));
            const max = maxValue ? Math.max(SelectionUtils.transformKey(key).length, maxValue) : 0;
            res[key] = max;
            sum += max;
        });
        keys.forEach(key => {
            res[key] = (res[key] + (res[key] ? sum : 0)) / sum;
        });
        return res;
    }, []);

    const columns: GridColDef[] = useMemo(() => {
        if (isFetching || !data) return [];
        const arr = data[`${selectedModel}_${selectedEntity}`]?.extraction;
        if (!arr || arr.length === 0) return [];
        const offset = offsets[`${selectedModel}_${selectedEntity}`] ?? 0;
        const page = getPage({ extraction: arr }, offset / 100, 100);
        const flexValues = getColumnFlexValues(page);
        return Object.keys(arr[0])
            .map(key => ({
                field: key,
                headerName: SelectionUtils.transformKey(key),
                maxWidth: maxWidths[key],
                flex: flexValues[key]
            }))
            .filter(v => v.flex > 0);
    }, [isFetching, data, selectedModel, selectedEntity, offsets, getColumnFlexValues]);

    const rows = useMemo(() => {
        if (isFetching || !data) return [];
        const arr = data[`${selectedModel}_${selectedEntity}`]?.extraction;
        if (!arr || !Array.isArray(arr) || arr.length === 0) return [];
        const offset = offsets[`${selectedModel}_${selectedEntity}`] ?? 0;
        const page = getPage({ extraction: arr }, offset / 100, 100);
        return (
            page.map(el => {
                let row: Record<string, any> = {};
                Object.keys(el).forEach(key => (row[key] = SelectionUtils.transformValue(key, el[key])));
                return row;
            }) ?? []
        );
    }, [isFetching, offsets, data, selectedModel, selectedEntity]);

    const isErrorEntity = false;

    const rowCount = useMemo(() => {
        return data ? data[`${selectedModel}_${selectedEntity}`]?.rowCount ?? 0 : 0;
    }, [data, selectedModel, selectedEntity]);

    const setRequestedPage = useCallback(
        (model: GridPaginationModel, details: GridCallbackDetails<any>) => {
            const offset = model.page * model.pageSize;
            setOffsets(prev => {
                return { ...prev, [`${selectedModel}_${selectedEntity}`]: offset };
            });
            requestTableEntity(polygon, selectedModel, selectedEntity as ResultEntity, undefined, offset);
        },
        [polygon, requestTableEntity, selectedEntity, selectedModel]
    );

    const table = useMemo(
        () => (
            <Table
                error={isErrorEntity}
                fetching={isFetchingEntity}
                columns={columns}
                rowCount={rowCount}
                rows={rows}
                selectedModel={selectedModel}
                selectedEntity={selectedEntity}
                setRequestedPage={setRequestedPage}
            />
        ),
        [isErrorEntity, isFetchingEntity, columns, rowCount, rows, selectedModel, selectedEntity, setRequestedPage]
    );

    if (!drawingMode || isDrawing || drawAlert !== 'ok') return <></>;

    if (isFetching)
        return (
            <OutlineContainer className={`${classes.loading}`}>
                <CircularProgress size={'1.5rem'} />
                <b>Tabella attributi</b>
            </OutlineContainer>
        );

    if (!drawingMode || !data) return <></>;

    if (!isOpen)
        return (
            <div className={classes.icon} onClick={toggleOpen}>
                <TableIcon /> <b>Tabella Attributi</b>
            </div>
        );

    return createPortal(
        <>
            <Draggable
                disabled={onlyTouchInput || isMobileView || isFullScreen}
                position={isMobileView || isFullScreen ? { x: 0, y: 0 } : undefined}
                handle=".handle"
                bounds="parent"
            >
                <OutlineContainer className={`${classes.container} ${isFullScreen ? classes.fullscreen : ''}`}>
                    <div className={`${classes.header} ${isFullScreen ? classes.fullscreen : ''}`}>
                        <div className={`${classes.titleBar} ${isFullScreen ? classes.fullscreen : ''} handle`}>
                            <span>Tabella Attributi</span>
                            <div className={classes.enlarge} onClick={toggleFullScreen}>
                                {isFullScreen ? <ResizeSmall /> : <ResizeBig />}
                            </div>
                            <div className={classes.close} onClick={toggleOpen}>
                                <Close />
                            </div>
                        </div>
                        <div className={`${classes.heading} ${isFullScreen ? classes.fullscreen : ''}`}>
                            <p>
                                <b>
                                    <span>Area: </span>Poligono selezionato
                                </b>
                            </p>
                        </div>
                        <div className={`${classes.selection} ${isFullScreen ? classes.fullscreen : ''}`}>
                            <div className={classes.models}>{models}</div>
                            <div className={classes.entities}>{entities}</div>
                        </div>
                    </div>
                    <div className={`${classes.table} ${isFullScreen ? classes.fullscreen : ''}`}>{table}</div>
                </OutlineContainer>
            </Draggable>
        </>,
        document.getElementById('table-portal') as HTMLElement
    );
};

