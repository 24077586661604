import { baseURL, epsg3857TileInfo } from 'helpers';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import TileGrid from 'ol/tilegrid/TileGrid';

export const createMapSource = (layerName: string, cached = window.location.search === '?gwc') => {
    return new TileWMS({
        url: `${baseURL}/geoserver/${cached ? 'gwc/service/' : ''}wms`,
        projection: 'EPSG:3857',
        hidpi: false,
        tileGrid: new TileGrid({
            extent: epsg3857TileInfo.extent,
            resolutions: epsg3857TileInfo.resolutions,
            tileSize: 512
        }),
        attributions:
            layerName === 'maps:osm'
                ? '© <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors.'
                : undefined,
        params: { LAYERS: layerName, TILED: true, SRS: 'EPSG:3857' },
        serverType: 'geoserver'
    });
};

export const mapLayer = new TileLayer({
    source: undefined,
    maxZoom: 19,
    zIndex: 0,
    properties: { id: 'map-layer', overlay: true }
});

