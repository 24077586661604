import { AnyAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { map, zoomPadding } from './constants';
import { mapActions } from 'store';
import { Geometry } from 'ol/geom';
import { View } from 'ol';

export const registerZoomHandler = (dispatch: Dispatch<AnyAction>) => {
    map.on(['moveend'], () => dispatch(mapActions.updateCurrentZoom()));
};

export const getTargetZoom = async (zoom: number, geom: Geometry): Promise<number> => {
    return new Promise(resolve => {
        const view = new View({
            projection: 'EPSG:3857',
            minZoom: zoom,
            maxZoom: 19,
            zoom,
        });
        view.fit(geom.getExtent(), {
            size: map.getSize(),
            maxZoom: 19,
            padding: zoomPadding,
            callback: () => resolve(view.getZoom()!),
        });
    });
};
