import { Popper } from '@mui/material';
import { modelTitles } from 'helpers';
import { RootState } from 'store';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FC } from 'react';
import classes from './InfoPopper.module.scss';
import colors from 'styles/modules/colors.module.scss';

export const InfoPopper: FC = () => {
    const { open, title, model, info } = useSelector((root: RootState) => root.popup);
    const infoRef = useRef<HTMLDivElement>(null);
    const [arrowRef, setArrowRef] = useState(null);

    return (
        <>
            <div id="info" ref={infoRef} className={classes.anchor} />
            <Popper
                open={open}
                key={`${open}`}
                anchorEl={infoRef.current}
                className={classes.popper}
                placement="auto"
                disablePortal={false}
                modifiers={[
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowRef
                        }
                    }
                ]}
            >
                <div>
                    <div ref={setArrowRef as any} className={classes.arrow} />
                    <div className={classes.paper}>
                        <b>{title}</b>
                        <b style={{ color: colors[model] }} className={classes.small}>
                            {`Modello ${modelTitles[model as keyof typeof modelTitles]}`}
                        </b>
                        <div className={`${classes.small} ${classes.props}`}>
                            {Object.entries(info).map(([key, value]) =>
                                value ? (
                                    <div key={key}>
                                        <span className={classes.key}>{key}:</span>&nbsp;
                                        <span className={classes.value}>{value}</span>
                                    </div>
                                ) : (
                                    <></>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </Popper>
        </>
    );
};

