import { CloseSelection } from 'components/icons';
import { FC } from 'react';
import classes from './ResetDrawButton.module.scss';

type ResetDrawButtonProps = FC<{
    onClick: () => void;
}>;

const ResetDrawButton: ResetDrawButtonProps = ({ onClick }) => {
    return (
        <div className={classes.button} onClick={onClick}>
            <CloseSelection />
        </div>
    );
};

export default ResetDrawButton;
