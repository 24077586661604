import { FC } from 'react';

export const Unita: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
            <path
                d="M3 0C1.3425 0 0 1.3425 0 3C0 4.6575 1.3425 6 3 6C4.6575 6 6 4.6575 6 3C6 1.3425 4.6575 0 3 0Z"
                fill="#353839"
            />
        </svg>
    );
};

