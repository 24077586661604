import JSZip from 'jszip';

export const validateZip = async (zipFile: Blob): Promise<{ error: boolean; empty?: boolean }> => {
    try {
        const zip = await JSZip.loadAsync(zipFile);
        if (Object.keys(zip.files).length === 0) {
            console.error('ZIP file is empty.');
            return { error: true, empty: true };
        }
    } catch (error) {
        console.error('Error validating ZIP file:', error);
        return { error: true };
    }
    return { error: false };
};

