import { InputAdornment, OutlinedInput, OutlinedInputProps } from '@mui/material';
import formClasses from '../FormContainer.module.scss';
import { FC, forwardRef, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const PasswordInput: FC<OutlinedInputProps> = forwardRef(({ error, ...field }, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <OutlinedInput
            classes={{ root: formClasses.outlinedInput }}
            autoComplete="off"
            error={error}
            ref={ref}
            type={showPassword ? 'text' : 'password'}
            {...field}
            endAdornment={
                <InputAdornment position="end" onClick={handleClickShowPassword} className={formClasses.eye}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                </InputAdornment>
            }
        />
    );
});
export default PasswordInput;

