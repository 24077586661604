import { FC } from 'react';
import classes from './Navbar.module.scss';
import { InfratelLogo, Login } from 'components/icons';
import { useNavigate, useMatch } from 'react-router-dom';

const Navbar: FC = () => {
    const navigate = useNavigate();
    const isLogin = useMatch('/login/*');

    return (
        <div id="navbar" className={classes.navbar}>
            <div className={classes.logo}>
                <InfratelLogo />
            </div>
            {isLogin ? (
                <div className={classes.toHome} onClick={() => navigate('/')}>
                    <b>Annulla</b>
                </div>
            ) : (
                <div className={classes.login} onClick={() => navigate('/login')}>
                    <Login />
                    <b>Login</b>
                </div>
            )}
        </div>
    );
};
export default Navbar;

