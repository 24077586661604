import { FC } from 'react';

export const Login: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="26" viewBox="0 0 15 26" fill="none">
            <path
                d="M7.43568 11.9671C8.61911 11.9671 9.77595 11.6162 10.7599 10.9587C11.7439 10.3012 12.5109 9.36669 12.9638 8.27335C13.4166 7.18 13.5351 5.9769 13.3042 4.81621C13.0734 3.65551 12.5035 2.58936 11.6667 1.75255C10.8299 0.915733 9.76372 0.345851 8.60303 0.114975C7.44234 -0.115902 6.23923 0.00259304 5.14588 0.455473C4.05253 0.908353 3.11802 1.67527 2.46054 2.65926C1.80306 3.64325 1.45215 4.8001 1.45215 5.98353C1.45379 7.56996 2.08472 9.09095 3.2065 10.2127C4.32827 11.3345 5.84925 11.9654 7.43568 11.9671ZM7.43568 1.32123C8.35758 1.32123 9.25877 1.59459 10.0253 2.10675C10.7919 2.61892 11.3894 3.34688 11.7422 4.19859C12.095 5.05031 12.1874 5.98753 12.0076 6.89173C11.8278 7.79594 11.384 8.62652 10.7321 9.27847C10.0803 9.93042 9.24979 10.3745 8.34562 10.5544C7.44145 10.7344 6.50423 10.6422 5.65245 10.2895C4.80067 9.93683 4.07257 9.3395 3.56026 8.57305C3.04795 7.8066 2.77443 6.90544 2.77425 5.98353C2.77542 4.74752 3.26687 3.56247 4.14078 2.68839C5.01469 1.81432 6.19967 1.32264 7.43568 1.32123Z"
                fill="white"
            />
            <path
                d="M7.43566 14.5167C5.46433 14.519 3.57439 15.3032 2.18044 16.6971C0.786494 18.0911 0.00234042 19.981 0 21.9523V26H1.3221V21.9523C1.3221 20.3309 1.96622 18.7759 3.11273 17.6294C4.25924 16.4829 5.81425 15.8388 7.43566 15.8388C9.05708 15.8388 10.6121 16.4829 11.7586 17.6294C12.9051 18.7759 13.5492 20.3309 13.5492 21.9523V26H14.8713V21.9523C14.869 19.981 14.0848 18.0911 12.6909 16.6971C11.2969 15.3032 9.407 14.519 7.43566 14.5167Z"
                fill="white"
            />
        </svg>
    );
};
