import { FC } from 'react';

export const Nodo: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
            <path
                d="M14.01 10.47H0.739999C0.539999 10.47 0.379999 10.31 0.379999 10.11V0.640029C0.379999 0.440029 0.539999 0.280029 0.739999 0.280029H14.01C14.21 0.280029 14.37 0.440029 14.37 0.640029V10.12C14.37 10.32 14.21 10.48 14.01 10.48V10.47Z"
                fill="#EAF4F7"
            />
            <path
                d="M14.38 10.75H0.38C0.17 10.75 0 10.58 0 10.37V0.38C0 0.17 0.17 0 0.38 0H14.38C14.59 0 14.76 0.17 14.76 0.38V10.38C14.76 10.59 14.59 10.76 14.38 10.76V10.75ZM0.76 10H14.01V0.75H0.75V10H0.76Z"
                fill="#353839"
            />
        </svg>
    );
};

