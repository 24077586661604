import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface State {
    token?: string;
}

export interface ResetPassword extends State {}

const name = 'resetPassword';
const initialState: State = createInitialState();
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const resetPasswordActions = { ...slice.actions };
export const resetPasswordReducer = slice.reducer;

function createInitialState() {
    return {
        token: ''
    } as State;
}

function createReducers() {
    return { setToken };

    function setToken(state: ResetPassword, action: PayloadAction<string | undefined>) {
        state.token = action.payload;
    }
}

