import { FC } from 'react';
import ProjectFinderBody from './projectFinderBody/ProjectFinderBody';
import { OutlineContainer } from 'components/containers';
import { LeftToolBarTypes } from 'helpers';
import { Search } from 'components/icons';
import { Arrow } from 'components/icons/Arrow';
import classes from './ProjectFinder.module.scss';

type ProjectFinderProps = FC<{
    activeTab: LeftToolBarTypes;
    setActiveTab: (tab: LeftToolBarTypes) => void;
}>;

const ProjectFinder: ProjectFinderProps = ({ activeTab, setActiveTab }) => {
    return (
        <OutlineContainer className={classes.round}>
            <div className={classes.header} onClick={() => setActiveTab(LeftToolBarTypes.find)}>
                <div className={classes.iconText}>
                    <Search />
                    <b>Cerca</b>
                </div>
                <Arrow direction={activeTab === LeftToolBarTypes.find ? 'up' : 'down'} />
            </div>
            <div>{activeTab === LeftToolBarTypes.find && <ProjectFinderBody />}</div>
        </OutlineContainer>
    );
};

export default ProjectFinder;

