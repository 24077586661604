import { createContext, FC, PropsWithChildren, useEffect, useCallback } from 'react';
import { createMapSource, getWorkspaceLayers, registerSelectionHandler } from 'helpers';
import LayerGroup from 'ol/layer/Group';
import TileLayer from 'ol/layer/Tile';
import { useDispatch } from 'react-redux';
import { mapActions, useGetRegionStatsQuery } from 'store';
import { createStatsLayers } from 'helpers/layers/statsLayer';
import { statsMaxZoom, registerZoomHandler } from 'helpers';

export const MapContext = createContext({});

export const MapProvider: FC<PropsWithChildren> = ({ children }) => {
    const { data: regionStats } = useGetRegionStatsQuery();
    const dispatch = useDispatch();

    const addLayersToMap = useCallback(
        async (id: string, minZoom = statsMaxZoom) => {
            const res = await getWorkspaceLayers(id);
            const layers = res.map(
                v =>
                    new TileLayer({
                        source: createMapSource(v.name),
                        minZoom: v.minZoom,
                        maxZoom: v.maxZoom,
                        zIndex: v.zIndex,
                        properties: { ...v, groupId: id }
                    })
            );
            const group = new LayerGroup({ layers, minZoom, properties: { id } });
            dispatch(mapActions.addLayer(group));
        },
        [dispatch]
    );

    useEffect(() => {
        addLayersToMap('webgis', 0);
        addLayersToMap('concessione');
        addLayersToMap('diretto');
        addLayersToMap('fwa');
        registerZoomHandler(dispatch);
        registerSelectionHandler(dispatch);
    }, [addLayersToMap, dispatch]);

    useEffect(() => {
        if (!regionStats) return;
        const layers = createStatsLayers(regionStats);
        layers.forEach(layer => dispatch(mapActions.addLayer(layer)));
    }, [dispatch, regionStats]);
    return <MapContext.Provider value={{}}>{children}</MapContext.Provider>;
};

