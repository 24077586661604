import { FC } from 'react';

export const NewEdit: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
            <path
                d="M18.202 15.2237V9.42554H16.8104V15.2237H11.0122V16.6153H16.8104V22.4135H18.202V16.6153H24.0002V15.2237H18.202Z"
                fill="#0050DE"
            />
            <path
                d="M10.8265 17.9883L8.6835 20.1313L1.54941 21.3188L2.73688 14.1847L12.4129 4.51795L13.4241 3.50675L15.261 1.66988L21.2076 7.6165L19.5841 9.24C19.5841 9.30493 19.6027 9.3606 19.6027 9.42554V11.1975L22.6827 8.11747C22.8125 7.98759 22.8867 7.81132 22.8867 7.62578C22.8867 7.44024 22.8125 7.26397 22.6827 7.13409L15.7434 0.194819C15.4836 -0.0649397 15.0198 -0.0649397 14.76 0.194819L1.59579 13.359C1.49375 13.4611 1.42881 13.591 1.40098 13.7394L0.00941016 22.061C-0.0276983 22.2836 0.0465186 22.5063 0.204229 22.664C0.334109 22.7938 0.510374 22.8681 0.695916 22.8681C0.733024 22.8681 0.770133 22.8681 0.807241 22.8681L9.1288 21.4765C9.27724 21.4487 9.40712 21.3837 9.50916 21.2817L12.7747 18.0161H11.0028C10.9378 18.0161 10.8822 18.0069 10.8172 17.9976L10.8265 17.9883Z"
                fill="#0050DE"
            />
        </svg>
    );
};
